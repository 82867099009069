var gerarCertificadoAnimal = function (animal, imgBase64) {

    if (animal == null)
        return "";

    var Certificado = window.Certificado;
    var Comum = window.Comum;

    var certificadoAnimal = [
        Comum.linhaHorizontal(true, -11, 0, 0, 5, 478),
        { text: animal.tituloCertificado, bold: true, lineHeight: 1.1, alignment: "center", fontSize: 10, margin: [-27, 0, 0, 0] },
        Comum.linhaHorizontal(true, -11, 0, 0, 5),
        { text: "DADOS PROPRIETÁRIO", alignment: "center", lineHeight: 1.1, margin: [0, 0, 0, 0] },
        Comum.linhaHorizontal(true, -11, 0, 0, 5),
        Certificado.dadosProprietario(animal),
        Certificado.dadosProprietarioEndereco(animal),
        Comum.linhaHorizontal(true, -11, 0, 0, 2),
        { text: "DADOS ANIMAL", alignment: "center", lineHeight: 1.1, margin: [0, 0, 0, 0] },
        Comum.linhaHorizontal(true, -11, 0, 0, 5),
        Certificado.dadosAnimal(animal),
        Comum.linhaHorizontal(true, -11, 0, 0, 2),
        { text: "RESENHA DESCRITIVA", alignment: "center", margin: [0, 0, 0, 0] },
        Comum.linhaHorizontal(true, -11, 0, 0, 0),
        Certificado.dadosNumeroResenha(animal),
        Certificado.resenhaDescritiva(animal),
        Comum.linhaHorizontal(true, -11, 0, 0, 2),
        { text: "RESENHA GRÁFICA", alignment: "center", lineHeight: 1.1, margin: [0, 0, 0, 0] },
        Comum.linhaHorizontal(true, -11, 0, 0, 5),
        Certificado.resenhaGrafica(imgBase64, animal.versao),
        Comum.linhaHorizontal(true, -11, animal.versao == 1 ? -20 : 0, 0, 2),
        { text: "FOTOS", alignment: "center", lineHeight: 1.1, margin: [0, 2, 0, 0] },
        Comum.linhaHorizontal(true, -11, 0, 0, 5),
        Certificado.fotos(animal),
        Certificado.fotosMarcas(animal),
        Comum.linhaHorizontal(true, -11, 5, 0, 2),
        { text: animal.mensagemDeclaracao, alignment: "left", fontSize: 7, lineHeight: 1.1, margin: [0, 0, 0, 0] }
    ];

    return {
        fontSize: 8,
        stack: certificadoAnimal
    };
};

