﻿(function () {
  window.Comum = {
      dadosProprietario: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Nome: ", bold: true }, dados.nomeProdutor], lineHeight: 1.3 });
          primeiraColuna.push({ text: [{ text: "Endereço: ", bold: true }, dados.endereco], lineHeight: 1.3 });
          primeiraColuna.push({ text: [{ text: "Estabelecimento: ", bold: true }, dados.estabelecimento], lineHeight: 1.3 });
          primeiraColuna.push({ text: [{ text: "Município: ", bold: true }, dados.municipio, "/", dados.uf], lineHeight: 1.3 });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "CPF/CNPJ: ", bold: true }, dados.cpfcnpjProdutor], lineHeight: 1.3 });
          segundaColuna.push({ text: [{ text: "Telefone: ", bold: true }, dados.telefone == null?"":dados.telefone], lineHeight: 1.3 });
          segundaColuna.push({ text: [{ text: "Inscrição: ", bold: true }, dados.inscricao], lineHeight: 1.3 });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: "70%" },
                  { stack: segundaColuna, width: "30%" }
              ]
          };
      },
      formatarAnimal: function (data) {
          return new vmPdfPublicoAnimal(data);
      },
      gerarPDF: function (conteudoPdf, posicao) {
          if (!posicao) posicao = '';
          return new Promise(function (solucao, rejeicao) {
              var doc = pdfMake.createPdf(conteudoPdf);
              doc.getBase64(function (data) {
                  var byteCharacters = atob(data);
                  var byteNumbers = new Array(byteCharacters.length);
                  for (var i = 0; i < byteCharacters.length; i++) {
                      byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  var byteArray = new Uint8Array(byteNumbers);
                  var blob = new Blob([byteArray], { type: 'application/pdf' });

                  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(blob, "Animal.pdf");
                  }
                  else {
                      var fileUrl = URL.createObjectURL(blob);
                      var iframe = document.createElement('iframe');
                      iframe.setAttributes({
                          frameBorder: 0,
                          allowfullscreen: true,
                          src: fileUrl,
                          style: {
                              border: 0,
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              width: '100%',
                              height: '100%',
                              position: "absolute"
                          }
                      });

                      var janela = window.open('', '_blank', 'maximized=true, resizable=yes, height=660, width=650' + posicao);
                      var style = janela.document.createElement('style');
                      style.type = "text/css";
                      style.innerHTML += "*, body{ padding: 0; margin: 0; display: none;  } ";
                      janela.document.getElementsByTagName("head")[0].appendChild(style);
                      janela.document.getElementsByTagName("html")[0].style.overflow = "hidden";
                      janela.document.write(iframe.outerHTML);
                      solucao();
                  }
              });
          });
      },
      informacoes: function (titulo, usuarioLogado) {
          return {
              title: titulo || "Certificado",
              author: usuarioLogado || "Teste"
          };
      },
      cabecalho: function (dados, titulo, qrCode) {
          if (LaboratorioUtil.EhNulo(titulo)) titulo = true;

          var objCabecalho = null;

          if (dados.exameAie != null) {
              objCabecalho = Cabecalho.Laboratorio(dados, qrCode);
              console.log("Laudo Exame");
          } else {
              objCabecalho = Cabecalho.IdentificacaoAnimal(dados, qrCode);
              console.log("Identificação animal");
          }

          if (titulo) {
              objCabecalho.stack.push({ canvas: [{ type: "line", x1: 30, y1: 0, x2: 615 - 30, y2: 0, lineWidth: 0.9 }], margin: [0, 2, 0, 0] });
              objCabecalho.stack.push({ text: dados.tituloCertificado, bold: true, lineHeight: 1.1, alignment: "center", margin: [0, 2, 0, 0] });
              objCabecalho.stack.push({ canvas: [{ type: "line", x1: 30, y1: 0, x2: 615 - 30, y2: 0, lineWidth: 0.9 }] });
          }

          return objCabecalho;
      },
      linhaHorizontal: function (margem, esq, top, dir, bas, largura) {
          if (window.LaboratorioUtil.EhBooleano(margem) && margem) {
              if (window.LaboratorioUtil.EhVazioOuNulo(esq)) esq = 0;
              if (window.LaboratorioUtil.EhVazioOuNulo(top)) top = 0;
              if (window.LaboratorioUtil.EhVazioOuNulo(dir)) dir = 0;
              if (window.LaboratorioUtil.EhVazioOuNulo(bas)) bas = 0;

              var linhaCanvas = window.LaboratorioUtil.linha(largura);
              linhaCanvas["margin"] = [esq, top, dir, bas];
              return linhaCanvas;
          } else {
              return window.LaboratorioUtil.linha();
          }
      }
  };
})();
