function GoogleAnalytics()
{
    var hours = 24; // Reset when storage is more than 24hours
    var now = new Date().getTime();
    var setupTime = sessionStorage.getItem('setupTime');
    
    if (setupTime == null) {
        sessionStorage.setItem('setupTime', now);
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'UA-159812648-1');
    } else {
        if (now - setupTime > hours * 60 * 60 * 1000) {
            sessionStorage.clear();
            sessionStorage.setItem('setupTime', now);
        }
    }

    return true;
};
