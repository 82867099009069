(function () {
    var Particularidade = function (localHtml) {
        if (!(this instanceof Particularidade)) {
            return new Particularidade(localHtml);
        }
        this.localHtml = localHtml;
        this.pastaImagem = root + "assets/";
        this.pastaParticularidades = "particularidades/";
        this.pastaBase = "base/";
        this.formato = ".png";

        this.imgsBase64 = [];
        this.arrCanvas = [];
        this.contexto = {};
        this.canvas = {};

        this.arrImagemCavalo = [{ desenhoId: 1, imagem: "LateralDireitaCavalo", largura: 400, altura: 351, classe: "lado-direito", multiplicador: 1, ajuste: [0, 0, 1, 1] },
                                { desenhoId: 2, imagem: "LateralEsquerdaCavalo", largura: 400, altura: 351, classe: "lado-esquerdo", multiplicador: 1, ajuste: [0, 0, 1, 1] },
                                { desenhoId: 3, imagem: "FrenteCabecaCavalo", largura: 200, altura: 270, classe: "cabeca", multiplicador: 0.8, ajuste: [0, 0, 1, 0.9] },
                                { desenhoId: 4, imagem: "InferiorPescocoCavalo", largura: 192, altura: 320, classe: "pescoco", multiplicador: 0.75, ajuste: [1, 47, 0.95, 0.83] },
                                { desenhoId: 5, imagem: "FocinhoCavalo", largura: 150, altura: 183, classe: "focinho", multiplicador: 0.8, ajuste: [-8, 0, 1, 1] },
                                { desenhoId: 6, imagem: "MembrosAnterioresCavalo", largura: 185, altura: 300, classe: "anteriores", multiplicador: 0.8, ajuste: [0, 40, 0.98, 0.84] },
                                { desenhoId: 7, imagem: "MembrosPosterioresCavalo", largura: 185, altura: 300, classe: "posteriores", multiplicador: 0.8, ajuste: [0, 40, 0.95, 0.84] }];

        //particularidades
        this.arrImagemParticularidades = [{ particularidadeId: 1, nome: "ALTO CALÇADO", imagem: "calcado", pasta: "membro/", multiplicador: 0.5, offset: [12, 2], lado: false },
        { particularidadeId: 2, nome: "ALTO CALÇADO - ARREGAÇADO", imagem: "calcado", pasta: "membro/", multiplicador: 0.5, offset: [12, 2], lado: false },
        { particularidadeId: 3, nome: "BAIXO CALÇADO", imagem: "calcado", pasta: "membro/", multiplicador: 0.5, offset: [12, 2], lado: false },
        { particularidadeId: 4, nome: "BEBE EM BRANCO", imagem: "bebe_em_branco", pasta: "focinho/", multiplicador: [0.82, 0.72], offset: [0, -28], lado: false },
        { particularidadeId: 5, nome: "BETA", imagem: "ladre_ou_beta", pasta: "focinho/", multiplicador: [0.82, 0.72], offset: [0, -25], lado: false },
        { particularidadeId: 6, nome: "BOCALVO", imagem: "bocalvo", pasta: "focinho/", multiplicador: [0.82, 0.72], offset: [0, -24], lado: false },
        { particularidadeId: 7, nome: "BRAGADO", imagem: "bragado", pasta: "tronco/", multiplicador: 0.43, offset: [10, 10], lado: false },
        { particularidadeId: 8, nome: "CALÇADO SOBRE COROA", imagem: "calcado_sobre_coroa_posterior", pasta: "membro/", multiplicador: 0.44, offset: [0, 0], lado: true },
        { particularidadeId: 9, nome: "ARMINHADO", imagem: "arminhado", pasta: "membro/", multiplicador: 0.39, offset: [5, 5], lado: false },
        { particularidadeId: 10, nome: "INCOMPLETO", imagem: "calcado_imcompleto", pasta: "membro/", multiplicador: 1, offset: [12, 2], lado: false },
        { particularidadeId: 11, nome: "CASCO BRANCO", imagem: "casco_branco_posterior", pasta: "membro/", multiplicador: 0.44, offset: [0, 0], lado: true },
        { particularidadeId: 13, nome: "CASCO RAJADO/MESCLADO", imagem: "casco_rajado_mesclado_posterior", pasta: "membro/", multiplicador: 0.44, offset: [0, 0], lado: true },
        { particularidadeId: 15, nome: "CICATRIZ", imagem: "cicatriz", pasta: "geral/", multiplicador: 0.8, offset: [10, 5], lado: false },
        { particularidadeId: 16, nome: "CORDÃO", imagem: "cordao", pasta: "cabeca/", multiplicador: 0.86, offset: [-1, 17], lado: false },
        { particularidadeId: 17, nome: "CORDÃO INTERROMPIDO", imagem: "cordao_interrompido", pasta: "cabeca/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false },
        { particularidadeId: 19, nome: "ESPIGA", imagem: "espiga_a_direita", pasta: "geral/", multiplicador: 0.8, offset: [8, 8], lado: false },
        { particularidadeId: 22, nome: "ESTRELA", imagem: "estrela", pasta: "cabeca/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false },
        { particularidadeId: 23, nome: "ESTRELA EM CORAÇÃO", imagem: "estrela_em_coracao", pasta: "cabeca/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false },
        { particularidadeId: 24, nome: "ESTRELA EM LOSANGO", imagem: "estrela_em_losango", pasta: "cabeca/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false },
        { particularidadeId: 25, nome: "ESTRELA EM MEIA LUA", imagem: "estrela_em_meia_lua", pasta: "cabeca/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false },
        { particularidadeId: 26, nome: "ESTRELA EM TRIÂNGULO", imagem: "estrela_em_triangulo", pasta: "cabeca/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false },
        { particularidadeId: 27, nome: "ESTRELA EM U", imagem: "estrela_em_u", pasta: "cabeca/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false },
        { particularidadeId: 28, nome: "FAIXA CRUCIAL", imagem: "faixa_crucial", pasta: "tronco/", multiplicador: 0.43, offset: [0, 0], lado: true },
        { particularidadeId: 29, nome: "FILETE", imagem: "filete", pasta: "cabeca/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false },
        { particularidadeId: 30, nome: "FILETE INTERROMPIDO", imagem: "filete_interrompido", pasta: "cabeca/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false },
        { particularidadeId: 31, nome: "FRENTE ABERTA", imagem: "frente_aberta", pasta: "cabeca/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false },
        { particularidadeId: 32, nome: "GOLPE DE LANÇA", imagem: "golpe_de_lanca", pasta: "geral/", multiplicador: 0.8, offset: [5, 5], lado: false },
        { particularidadeId: 33, nome: "LADRE", imagem: "ladre_ou_beta", pasta: "focinho/", multiplicador: [0.82, 0.72], offset: [-10, 0], lado: false },
        { particularidadeId: 34, nome: "LISTRA DE BURRO", imagem: "listra_de_burro", pasta: "tronco/", multiplicador: 0.43, offset: [0, 0], lado: true },
        { particularidadeId: 35, nome: "LUZEIRO", imagem: "luzeiro", pasta: "cabeca/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false },
        { particularidadeId: 36, nome: "MALACARA", imagem: "malacara", pasta: "cabeca/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false },
        { particularidadeId: 37, nome: "MARCA A FOGO", imagem: "marca_a_fogo", pasta: "geral/", multiplicador: 1, offset: [10, 10], lado: false },
        { particularidadeId: 38, nome: "MARCA A FRIO", imagem: "marca_a_frio", pasta: "geral/", multiplicador: 1, offset: [10, 10], lado: false },
        { particularidadeId: 39, nome: "MÉDIO CALÇADO", imagem: "calcado", pasta: "membro/", multiplicador: 0.5, offset: [12, 2], lado: false },
        { particularidadeId: 40, nome: "PÊLOS BRANCOS", imagem: "pelos_brancos", pasta: "geral/", multiplicador: 0.86, offset: [10, 10], lado: false },
        { particularidadeId: 41, nome: "PISADURA", imagem: "pisadura", pasta: "tronco/", multiplicador: 0.43, offset: [10, 10], lado: false },
        { particularidadeId: 43, nome: "RODOPIO", imagem: "rodopio", pasta: "geral/", multiplicador: 0.75, offset: [8, 8], lado: false },
        { particularidadeId: 46, nome: "VESTÍGIO DE FAIXA CRUCIAL", imagem: "vestigio_de_faixa_crucial", pasta: "tronco/", multiplicador: 0.43, offset: [0, 0], lado: true },
        { particularidadeId: 47, nome: "VESTÍGIO/INÍCIO DE FILETE", imagem: "vestigio_inicio_de_filete", pasta: "cabeca/", multiplicador: 0.86, offset: [-1, 13], lado: false },
        { particularidadeId: 48, nome: "ZEBRURAS", imagem: "zebruras", pasta: "membro/", multiplicador: 0.3, offset: [8, 8], lado: false },
        { particularidadeId: 49, nome: "ALTO CALÇADO INCOMPLETO", imagem: "calcado_incompleto", pasta: "membro/", multiplicador: 0.5, offset: [12, 2], lado: false },
        { particularidadeId: 50, nome: "BAIXO CALÇADO INCOMPLETO", imagem: "calcado_incompleto", pasta: "membro/", multiplicador: 0.5, offset: [12, 2], lado: false },
        { particularidadeId: 51, nome: "MÉDIO CALÇADO INCOMPLETO", imagem: "calcado_incompleto", pasta: "membro/", multiplicador: 0.5, offset: [12, 2], lado: false },
        { particularidadeId: 52, nome: "LUZEIRO COM INÍCIO DE FILETE", imagem: "luzeiro_com_inicio_de_filete", pasta: "cabeca/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false },
        //{ particularidadeId: 53, nome: "ESPIGA A ESQUERDA", imagem: "espiga_a_esquerda", pasta: "geral/", multiplicador: [0.86, 0.81], offset: [8, 8], lado: false },
        //{ particularidadeId: 54, nome: "ESPIGA ASCENDENTE", imagem: "espiga_inferior", pasta: "geral/", multiplicador: [0.86, 0.81], offset: [8, 8], lado: false },
        //{ particularidadeId: 55, nome: "ESPIGA DESCENDENTE", imagem: "espiga_superior", pasta: "geral/", multiplicador: [0.86, 0.81], offset: [8, 8], lado: false },
        { particularidadeId: 56, nome: "MANCHA", imagem: "mancha", pasta: "geral/", multiplicador: [0.86, 0.81], offset: [8, 8], lado: false },
        { particularidadeId: 57, nome: "BEBE EM BRANCO INFERIOR", imagem: "bebe_em_branco_inferior", pasta: "focinho/", multiplicador: [0.82, 0.72], offset: [0, -25], lado: false },
        { particularidadeId: 58, nome: "BEBE EM BRANCO SUPERIOR", imagem: "bebe_em_branco_superior", pasta: "focinho/", multiplicador: [0.82, 0.72], offset: [0, -25], lado: false },
        { particularidadeId: 59, nome: "CASCO BRANCO", imagem: "casco_branco_anterior", pasta: "membro/", multiplicador: 0.44, offset: [0, 0], lado: true },
        { particularidadeId: 60, nome: "CASCO RAJADO/MESCLADO", imagem: "casco_rajado_mesclado_anterior", pasta: "membro/", multiplicador: 0.44, offset: [0, 0], lado: true },
        { particularidadeId: 61, nome: "CALÇADO SOBRE COROA", imagem: "calcado_sobre_coroa_anterior", pasta: "membro/", multiplicador: 0.44, offset: [0, 0], lado: true }];

        this.arrEspelhos = [
        { espelhoId: 1, nome: 'Calcamento Anterior Direito', trigger: [1, 2, 3, 39], lado: "DIREITO", posicao: "ANTERIOR", idDesenho: 2, eixoFixo: 132, imagem: 'membro/calcado' },
        { espelhoId: 2, nome: 'Calcamento Posterior Direito', trigger: [1, 2, 3, 39], lado: "DIREITO", posicao: "POSTERIOR", idDesenho: 2, eixoFixo: 333, imagem: 'membro/calcado' },
        { espelhoId: 3, nome: 'Calcamento Incompleto Anterior Direito', trigger: [10, 49, 50, 51], lado: "DIREITO", posicao: "ANTERIOR", idDesenho: 2, eixoFixo: 132, imagem: 'membro/calcado_incompleto' },
        { espelhoId: 4, nome: 'Calcamento Incompleto Posterior Direito', trigger: [10, 49, 50, 51], lado: "DIREITO", posicao: "POSTERIOR", idDesenho: 2, eixoFixo: 333, imagem: 'membro/calcado_incompleto' },
        { espelhoId: 14, nome: 'Bocalvo', trigger: [6], idDesenho: 2, eixoFixo: 0, imagem: 'tronco/espelho/bocalvo_esquerdo' },
        { espelhoId: 15, nome: 'Ladre', trigger: [5], idDesenho: 2, eixoFixo: 0, imagem: 'tronco/espelho/ladre_ou_beta_esquerdo' },
        { espelhoId: 16, nome: 'Bebe em Branco', trigger: [4], idDesenho: 2, eixoFixo: 0, imagem: 'tronco/espelho/bebe_em_branco_esquerdo' },
        { espelhoId: 17, nome: 'Bebe em Branco Superior', trigger: [58], idDesenho: 2, eixoFixo: 0, imagem: 'tronco/espelho/bebe_em_branco_superior_esquerdo' },
        { espelhoId: 18, nome: 'Bebe em Branco Inferior', trigger: [57], idDesenho: 2, eixoFixo: 0, imagem: 'tronco/espelho/bebe_em_branco_inferior_esquerdo' },
        { espelhoId: 24, nome: 'Casco Anterior', trigger: [59], idDesenho: 2, eixoFixo: 0, imagem: 'membro/espelho/casco_branco_direito_anterior' },
        { espelhoId: 25, nome: 'Casco Anterior Rajado', trigger: [60], idDesenho: 2, eixoFixo: 0, imagem: 'membro/espelho/casco_rajado_mesclado_direito_anterior' },
        { espelhoId: 26, nome: 'Casco Posterior', trigger: [11], idDesenho: 2, eixoFixo: 0, imagem: 'membro/espelho/casco_branco_direito_posterior' },
        { espelhoId: 27, nome: 'Casco Posterior Rajado', trigger: [13], idDesenho: 2, eixoFixo: 0, imagem: 'membro/espelho/casco_rajado_mesclado_direito_posterior' },


        { espelhoId: 5, nome: 'Calcamento Anterior Esquerdo', trigger: [1, 2, 3, 39], lado: "ESQUERDO", posicao: "ANTERIOR", idDesenho: 1, eixoFixo: 271, imagem: 'membro/calcado' },
        { espelhoId: 6, nome: 'Calcamento Posterior Esquerdo', trigger: [1, 2, 3, 39], lado: "ESQUERDO", posicao: "POSTERIOR", idDesenho: 1, eixoFixo: 74, imagem: 'membro/calcado' },
        { espelhoId: 7, nome: 'Calcamento Incompleto Anterior Esquerdo', trigger: [10, 49, 50, 51], lado: "ESQUERDO", posicao: "ANTERIOR", idDesenho: 1, eixoFixo: 271, imagem: 'membro/calcado_incompleto' },
        { espelhoId: 8, nome: 'Calcamento Incompleto Posterior Esquerdo', trigger: [10, 49, 50, 51], lado: "ESQUERDO", posicao: "POSTERIOR", idDesenho: 1, eixoFixo: 74, imagem: 'membro/calcado_incompleto' },
        { espelhoId: 19, nome: 'Bocalvo', trigger: [6], idDesenho: 1, eixoFixo: 0, imagem: 'tronco/espelho/bocalvo_direito' },
        { espelhoId: 20, nome: 'Ladre', trigger: [5], idDesenho: 1, eixoFixo: 0, imagem: 'tronco/espelho/ladre_ou_beta_direito' },
        { espelhoId: 21, nome: 'Bebe em Branco', trigger: [4], idDesenho: 1, eixoFixo: 0, imagem: 'tronco/espelho/bebe_em_branco_direito' },
        { espelhoId: 22, nome: 'Bebe em Branco Superior', trigger: [58], idDesenho: 1, eixoFixo: 0, imagem: 'tronco/espelho/bebe_em_branco_superior_direito' },
        { espelhoId: 23, nome: 'Bebe em Branco Inferior', trigger: [57], idDesenho: 1, eixoFixo: 0, imagem: 'tronco/espelho/bebe_em_branco_inferior_direito' },
        { espelhoId: 28, nome: 'Casco Anterior', trigger: [59], idDesenho: 1, eixoFixo: 0, imagem: 'membro/espelho/casco_branco_esquerdo_anterior' },
        { espelhoId: 29, nome: 'Casco Anterior Rajado', trigger: [60], idDesenho: 1, eixoFixo: 0, imagem: 'membro/espelho/casco_rajado_mesclado_esquerdo_anterior' },
        { espelhoId: 30, nome: 'Casco Posterior', trigger: [11], idDesenho: 1, eixoFixo: 0, imagem: 'membro/espelho/casco_branco_esquerdo_posterior' },
        { espelhoId: 31, nome: 'Casco Posterior Rajado', trigger: [13], idDesenho: 1, eixoFixo: 0, imagem: 'membro/espelho/casco_rajado_mesclado_esquerdo_posterior' },

        { espelhoId: 9, nome: 'Bocalvo', trigger: [6], idDesenho: 3, eixoFixo: 6, imagem: 'cabeca/bocalvo' },
        { espelhoId: 10, nome: 'Ladre', trigger: [5], idDesenho: 3, eixoFixo: 4, imagem: 'cabeca/ladre_ou_beta' },
        { espelhoId: 11, nome: 'Bebe em Branco', trigger: [4], idDesenho: 3, eixoFixo: 5, imagem: 'cabeca/bebe_em_branco' },
        { espelhoId: 12, nome: 'Bebe em Branco Superior', trigger: [58], idDesenho: 3, eixoFixo: 5, imagem: 'cabeca/bebe_em_branco_superior' },
        { espelhoId: 13, nome: 'Bebe em Branco Inferior', trigger: [57], idDesenho: 3, eixoFixo: 5, imagem: 'cabeca/bebe_em_branco_infeior' },


        { espelhoId: 32, nome: 'Calçado sobre Coroa Anterior Direito', trigger: [61], lado: "DIREITO", idDesenho: 2, eixoFixo: 0, imagem: 'membro/espelho/calcado_sobre_coroa_anterior_direito' },
        { espelhoId: 33, nome: 'Calçado sobre Coroa Anterior Esquerdo', trigger: [61], lado: "ESQUERDO", idDesenho: 1, eixoFixo: 0, imagem: 'membro/espelho/calcado_sobre_coroa_anterior_esquerdo' },
        { espelhoId: 34, nome: 'Calçado sobre Coroa Posterior Direito', trigger: [8], lado: "DIREITO", idDesenho: 2, eixoFixo: 0, imagem: 'membro/espelho/calcado_sobre_coroa_posterior_direito' },
        { espelhoId: 35, nome: 'Calçado sobre Coroa Posterior Esquerdo', trigger: [8], lado: "ESQUERDO", idDesenho: 1, eixoFixo: 0, imagem: 'membro/espelho/calcado_sobre_coroa_posterior_esquerdo' },

        { espelhoId: 40, nome: 'Casco Anterior Esquerdo', trigger: [59], lado: "ESQUERDO", idDesenho: 6, eixoFixo: 0, imagem: 'membro/espelho/membros/casco_branco_esquerdo_anterior' },
        { espelhoId: 41, nome: 'Casco Posterior Esquerdo', trigger: [11], lado: "ESQUERDO", idDesenho: 7, eixoFixo: 0, imagem: 'membro/espelho/membros/casco_branco_esquerdo_posterior' },
        { espelhoId: 42, nome: 'Casco Rajado Anterior Esquerdo', trigger: [60], lado: "ESQUERDO", idDesenho: 6, eixoFixo: 0, imagem: 'membro/espelho/membros/casco_rajado_esquerdo_anterior' },
        { espelhoId: 43, nome: 'Casco Rajado Posterior Esquerdo', trigger: [13], lado: "ESQUERDO", idDesenho: 7, eixoFixo: 0, imagem: 'membro/espelho/membros/casco_rajado_esquerdo_posterior' },

        { espelhoId: 44, nome: 'Casco Anterior Direito', trigger: [59], lado: "DIREITO", idDesenho: 6, eixoFixo: 0, imagem: 'membro/espelho/membros/casco_branco_direito_anterior' },
        { espelhoId: 45, nome: 'Casco Posterior Direito', trigger: [11], lado: "DIREITO", idDesenho: 7, eixoFixo: 0, imagem: 'membro/espelho/membros/casco_branco_direito_posterior' },
        { espelhoId: 46, nome: 'Casco Rajado Anterior Direito', trigger: [60], lado: "DIREITO", idDesenho: 6, eixoFixo: 0, imagem: 'membro/espelho/membros/casco_rajado_direito_anterior' },
        { espelhoId: 47, nome: 'Casco Rajado Posterior Direito', trigger: [13], lado: "DIREITO", idDesenho: 7, eixoFixo: 0, imagem: 'membro/espelho/membros/casco_rajado_direito_posterior' },

        { espelhoId: 48, nome: 'Calcado sobre a coroa Anterior Esquerdo', trigger: [61], lado: "ESQUERDO", idDesenho: 6, eixoFixo: 0, imagem: 'membro/espelho/calcado_sobre_a_coroa_anterior_esquerdo' },
        { espelhoId: 49, nome: 'Calcado sobre a coroa Posterior Esquerdo', trigger: [8], lado: "ESQUERDO", idDesenho: 7, eixoFixo: 0, imagem: 'membro/espelho/calcado_sobre_a_coroa_posterior_esquerdo' },

        { espelhoId: 50, nome: 'Calcado sobre a coroa Anterior Direito', trigger: [61], lado: "DIREITO", idDesenho: 6, eixoFixo: 0, imagem: 'membro/espelho/calcado_sobre_a_coroa_anterior_direito' },
        { espelhoId: 51, nome: 'Calcado sobre a coroa Posterior Direito', trigger: [8], lado: "DIREITO", idDesenho: 7, eixoFixo: 0, imagem: 'membro/espelho/calcado_sobre_a_coroa_posterior_direito' },

        { espelhoId: 52, nome: 'Calcamento Anterior Direito', trigger: [1, 2, 3, 39], lado: "DIREITO", posicao: "ANTERIOR", idDesenho: 6, eixoFixo: 140, imagem: 'membro/calcado' },
        { espelhoId: 53, nome: 'Calcamento Anterior Esquerdo', trigger: [1, 2, 3, 39], lado: "ESQUERDO", posicao: "ANTERIOR", idDesenho: 6, eixoFixo: 50, imagem: 'membro/calcado' },
        { espelhoId: 54, nome: 'Calcamento Posterior Direito', trigger: [1, 2, 3, 39], lado: "DIREITO", posicao: "POSTERIOR", idDesenho: 7, eixoFixo: 140, imagem: 'membro/calcado' },
        { espelhoId: 55, nome: 'Calcamento Posterior Esquerdo', trigger: [1, 2, 3, 39], lado: "ESQUERDO", posicao: "POSTERIOR", idDesenho: 7, eixoFixo: 50, imagem: 'membro/calcado' },

        { espelhoId: 56, nome: 'Calcamento Incompleto Anterior Direito', trigger: [10, 49, 50, 51], lado: "DIREITO", posicao: "ANTERIOR", idDesenho: 6, eixoFixo: 140, imagem: 'membro/calcado_incompleto' },
        { espelhoId: 57, nome: 'Calcamento Incompleto Anterior Esquerdo', trigger: [10, 49, 50, 51], lado: "ESQUERDO", posicao: "ANTERIOR", idDesenho: 6, eixoFixo: 50, imagem: 'membro/calcado_incompleto' },
        { espelhoId: 58, nome: 'Calcamento Incompleto Posterior Direito', trigger: [10, 49, 50, 51], lado: "DIREITO", posicao: "POSTERIOR", idDesenho: 7, eixoFixo: 140, imagem: 'membro/calcado_incompleto' },
        { espelhoId: 59, nome: 'Calcamento Incompleto Posterior Esquerdo', trigger: [10, 49, 50, 51], lado: "ESQUERDO", posicao: "POSTERIOR", idDesenho: 7, eixoFixo: 50, imagem: 'membro/calcado_incompleto' }

        ];

        this.CaminhoImagemBase = function (img) {
            return this.CaminhoImagem(this.pastaBase, img);
        };

        this.CaminhoImagemParticularidade = function (img) {
            return this.CaminhoImagem(this.pastaParticularidades, img);
        };

        this.CaminhoImagem = function (pasta, arquivo) {
            return this.pastaImagem + pasta + arquivo + this.formato;
        };

        this.Callback = function () { }

        this.EhNulo = function (x) {
            if ((x == 'undefined') || (x == null)) { return true; }
            else { return false; }
        };

        this.EhArray = function (x) {
            if (!this.EhNulo(x)) {
                if (x.constructor == Array) { return true; }
                else { return false; }
            }
            else { return false; }
        };

        this.EhFuncao = function (x) {
            if (!this.EhNulo(x)) {
                if (x instanceof Function) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        };

        this.EhVazioOuNulo = function (x) {
            if ((x == 'undefined') || (x == null) || x == "") { return true; }
            else { return false; }
        };

        this.ObterParticularidade = function (particularidadeId) {
            var obj = null;
            this.arrImagemParticularidades.forEach(function (imgPart) {
                if (imgPart.particularidadeId === particularidadeId) {
                    obj = imgPart;
                    return;
                }
            });
            return obj;
        }

        this.CarregarImagem = function (url) {
            return new Promise(function (solucao, rejeicao) {
                var img = new Image();
                img.onload = function () { solucao(img); };
                img.onerror = function () { rejeicao(new Error("carregamento: " + url + " falhou")); };
                img.src = url;
            });
        };

        this.DesenharParticularidade = function (opcoes) {
            var $this = this;
            return this.CarregarImagem(opcoes.uri).then(function (img) {
                //console.log(img.src);
                var multiplicadorLargura = 1;
                var multiplicadorAltura = 1;
                var tamanhoProporcionalDaImagem = opcoes.multiplicador;
                if ($this.EhArray(tamanhoProporcionalDaImagem)) {
                    multiplicadorLargura = tamanhoProporcionalDaImagem[0];
                    multiplicadorAltura = tamanhoProporcionalDaImagem[1];
                } else {
                    multiplicadorLargura = opcoes.multiplicador;
                    multiplicadorAltura = opcoes.multiplicador;
                }

                if (opcoes.x <= 0) {
                    $this.contexto[opcoes.classe].drawImage(img, 0, 0, opcoes.largura, opcoes.altura);
                } else {
                    var largura = img.width * multiplicadorLargura * opcoes.multiplicadorParticularidade;
                    var altura = img.height * multiplicadorAltura * opcoes.multiplicadorParticularidade;

                    if (opcoes.radiante != null && opcoes.radiante != 0) {
                        var pontoX = opcoes.x + 10;
                        var pontoY = opcoes.y - (altura / 2) + 20;
                        var larguraMarca = largura;
                        var alturaMarca = altura;

                        const posX = -(larguraMarca - 4);
                        const posY = -(alturaMarca / 2);
                        $this.contexto[opcoes.classe].save();
                        $this.contexto[opcoes.classe].translate(pontoX, pontoY);
                        $this.contexto[opcoes.classe].rotate(opcoes.radiante);
                        $this.contexto[opcoes.classe].drawImage(img, posX, posY, larguraMarca, alturaMarca);
                        $this.contexto[opcoes.classe].restore();
                    } else {
                        $this.contexto[opcoes.classe].drawImage(img, opcoes.x, opcoes.y, largura, altura);
                    }
                }
            });
        };

        this.Desenhar = function (arrParticularidades, callback) {
            var $this = this;

            $this.Callback = callback || $this.Callback;

            var divBase = document.createElement('div');
            var arrImgBase = [];
            var arrImgParticularidade = [];
            $this.arrImagemCavalo.forEachAsync(function (imgCavalo) {
                var idCanvas = "img_" + imgCavalo.desenhoId;

                var canvas = document.createElement('canvas');
                var contexto = canvas.getContext('2d');
                canvas.setAttributes({
                    id: idCanvas,
                    width: Math.ceil(imgCavalo.largura * imgCavalo.multiplicador),
                    height: Math.ceil(imgCavalo.altura * imgCavalo.multiplicador),
                    class: imgCavalo.classe,
                    desenhoId: imgCavalo.desenhoId,
                    style: {
                        border: 1
                    }
                });

                divBase.appendChild(canvas);

                var opcoes = {
                    uri: $this.CaminhoImagemBase(imgCavalo.imagem),
                    x: 0,
                    y: 0,
                    largura: canvas.width,
                    altura: canvas.height,
                    desenhoId: imgCavalo.desenhoId,
                    multiplicador: imgCavalo.multiplicador,
                    ajuste: imgCavalo.ajuste
                };

                arrImgBase.push($this.CarregarImagem(opcoes.uri).then(function (img) {

                    var largura = opcoes.largura;
                    var altura = opcoes.altura;

                    contexto.drawImage(img, opcoes.x, opcoes.y, largura, altura);

                    $this.arrCanvas.push(canvas);

                    $this.contexto[imgCavalo.classe] = contexto;
                    $this.canvas[imgCavalo.classe] = canvas;

                    return imgCavalo;
                }));
            }, function () {
                Promise.all(arrImgBase).then(function (imgsCavalo) {
                    imgsCavalo.forEachAsync(function (imgCavalo) {
                        for (var i = 0; i < arrParticularidades.length; i++) {
                            var particularidadeItem = arrParticularidades[i];

                            if (particularidadeItem.desenhoId === imgCavalo.desenhoId) {
                                var particularidade = $this.ObterParticularidade(particularidadeItem.particularidadeId);

                                var offsetX = particularidade.offset[0];
                                var offsetY = particularidade.offset[1];

                                var posX = (particularidadeItem.eixoX - offsetX);
                                var posY = (particularidadeItem.eixoY - offsetY);

                                //Ajuste proporcional ao tamanho da imagem base no ambito geral
                                var tamanhoProporcionalDaImagem = imgCavalo.multiplicador;
                                posX *= tamanhoProporcionalDaImagem;
                                posY *= tamanhoProporcionalDaImagem;

                                //Ajuste a localização da imagem base
                                var ajusteEixoX = imgCavalo.ajuste[0];
                                var ajusteEixoY = imgCavalo.ajuste[1];
                                posX += ajusteEixoX;
                                posY += ajusteEixoY;

                                var controleLado = 1;
                                var lado = "";
                                if (particularidade.lado)
                                    if (particularidadeItem.desenhoId === 1) {
                                        lado = "_direito";
                                    } else {
                                        lado = "_esquerdo";
                                        controleLado = -1;
                                    }

                                //Ajuste proporcional da dimenção da imagem base nos ambitos dos eixos x e y
                                var ajusteProporcaoEixoX = imgCavalo.ajuste[2];
                                var ajusteProporcaoEixoY = imgCavalo.ajuste[3];
                                posX *= ajusteProporcaoEixoX * controleLado;
                                posY *= ajusteProporcaoEixoY;

                                arrImgParticularidade.push($this.DesenharParticularidade({
                                    largura: $this.canvas[imgCavalo.classe].width,
                                    altura: $this.canvas[imgCavalo.classe].height,
                                    uri: $this.CaminhoImagemParticularidade(particularidade.pasta + particularidade.imagem + lado),
                                    x: posX,
                                    y: posY,
                                    radiante: particularidadeItem.radiante,
                                    multiplicador: particularidade.multiplicador,
                                    multiplicadorParticularidade: (imgCavalo.multiplicador >= 1 ? 0.8 : imgCavalo.multiplicador) / 1.3,
                                    classe: imgCavalo.classe
                                }));
                            }
                            else {
                                // espelhos
                                $this.arrEspelhos.forEach(function (f, i) {
                                    if (f.trigger.contains(particularidadeItem.particularidadeId)
                                            && f.idDesenho == imgCavalo.desenhoId
                                            && (f.lado == null || particularidadeItem.lado == f.lado)
                                            && (f.posicao == null || particularidadeItem.posicao == f.posicao)) {

                                        var particularidade = $this.ObterParticularidade(particularidadeItem.particularidadeId);


                                        var offsetX = particularidade.offset[0];
                                        var offsetY = particularidade.offset[1];

                                        var posX = ((f.eixoFixo > 0 ? f.eixoFixo : particularidadeItem.eixoX) - offsetX);
                                        var posY = (particularidadeItem.eixoY - offsetY);

                                        //Ajuste proporcional ao tamanho da imagem base no ambito geral
                                        var tamanhoProporcionalDaImagem = imgCavalo.multiplicador;
                                        posX *= tamanhoProporcionalDaImagem;
                                        posY *= tamanhoProporcionalDaImagem;

                                        //Ajuste a localização da imagem base
                                        var ajusteEixoX = imgCavalo.ajuste[0];
                                        var ajusteEixoY = imgCavalo.ajuste[1];
                                        posX += ajusteEixoX;
                                        posY += ajusteEixoY;

                                        //Ajuste proporcional da dimenção da imagem base nos ambitos dos eixos x e y
                                        var ajusteProporcaoEixoX = imgCavalo.ajuste[2];
                                        var ajusteProporcaoEixoY = imgCavalo.ajuste[3];
                                        posX *= ajusteProporcaoEixoX;
                                        posY *= ajusteProporcaoEixoY;
                                        if ((f.idDesenho == 6 || f.idDesenho == 7) && (f.trigger[3] == 39 || f.trigger[3] == 51)) {
                                            posY *= 0.52;
                                        }

                                        arrImgParticularidade.push($this.DesenharParticularidade({
                                            largura: $this.canvas[imgCavalo.classe].width,
                                            altura: $this.canvas[imgCavalo.classe].height,
                                            uri: $this.CaminhoImagemParticularidade(f.imagem),
                                            x: posX,
                                            y: posY,
                                            radiante: particularidadeItem.radiante,
                                            multiplicador: particularidade.multiplicador,
                                            multiplicadorParticularidade: (imgCavalo.multiplicador >= 1 ? 0.8 : imgCavalo.multiplicador) / 1.3,
                                            classe: imgCavalo.classe
                                        }));
                                    }
                                });
                            }
                        }
                    },
                    function () {
                        Promise.all(arrImgParticularidade).then(function () {
                            $this.arrCanvas.ordenarAsc();
                            $this.Callback();
                        });
                    });
                });
            }, this);
        };
    };

    Object.defineProperty(Element.prototype, 'posY', {
        get: function () {
            return this.offsetTop + (this.offsetParent ? this.offsetParent.posY : 0);
        }
    });

    Object.defineProperty(Element.prototype, 'posX', {
        get: function () {
            return this.offsetLeft + (this.offsetParent ? this.offsetParent.posX : 0);
        }
    });

    if (!(Element.prototype.setAttributes instanceof Function))
        Element.prototype.setAttributes = function (attrs) {
            for (var idx in attrs) {
                if ((idx == 'styles' || idx == 'style') && typeof attrs[idx] == 'object') {
                    for (var prop in attrs[idx]) { this.style[prop] = attrs[idx][prop] }
                } else if (idx == 'html') {
                    this.innerHTML = attrs[idx];
                } else {
                    this.setAttribute(idx, attrs[idx]);
                }
            }
        };

    if (!(Array.prototype.contains instanceof Function))
        Array.prototype.contains = function (obj) {
            var i = this.length;
            while (i--) {
                if (this[i] === obj) {
                    return true;
                }
            }
            return false;
        };

    Array.prototype.forEachAsync = function (callback, end) {
        var self = this;
        function task(index) {
            if (index >= self.length) {
                end();
            }
            else {
                callback(self[index], index, self);
                task(index + 1);
            }
        }

        task(0);
    };

    Array.prototype.ordenarAsc = function () {
        var tocado;
        do {
            tocado = false;
            for (var i = 0; i < this.length - 1; i++) {
                var desenhoAnimalId = parseInt(this[i].getAttribute("desenhoId"));
                var desenhoAnimalIdPosterior = parseInt(this[i + 1].getAttribute("desenhoId"));
                if (desenhoAnimalId > desenhoAnimalIdPosterior) {
                    var temp = this[i];
                    this[i] = this[i + 1];
                    this[i + 1] = temp;
                    tocado = true;
                }
            }
        } while (tocado);
    }

    Particularidade.fn = Particularidade.prototype = {
        desenhar: function (arrParticularidades) {

            var $this = this;

            return new Promise(function (solucao, rejeicao) {
                $this.Desenhar(arrParticularidades, function () {
                    for (var i = 0; i < $this.arrCanvas.length; i++) {
                        document.getElementById($this.localHtml).appendChild($this.arrCanvas[i]);
                    }
                    solucao();
                });
            });
        },
        obterBase64Desenho: function (arrParticularidades, config) {

            var $this = this;
            config = config || {};
            var padrao = { width: 900, height: 500 };

            return new Promise(function (solucao, rejeicao) {
                $this.Desenhar(arrParticularidades, function () {
                    var canvas = document.createElement("canvas");
                    canvas.width = config.width || padrao.width;
                    canvas.height = config.height || padrao.height;
                    var contexto = canvas.getContext("2d");

                    var promessasImagens = [];
                    $this.arrCanvas.forEachAsync(function (tagCanvas, index, arr, next) {
                        var classe = tagCanvas.getAttribute("class");
                        promessasImagens.push(new Promise(function (solucao, rejeicao) {
                            var img = new Image();
                            img.src = tagCanvas.toDataURL("image/png");
                            img.onload = function () { solucao(img); }
                            img.onerror = function () { rejeicao(new Error("carregamento: " + classe + " falhou")); };
                        }).then(function (img) {
                            var posicao = {
                                "lado-direito": { posX: 20, posY: 90 },
                                "lado-esquerdo": { posX: 474, posY: 90 },
                                "cabeca": { posX: 371, posY: 180 },
                                "pescoco": { posX: 288, posY: 291 },
                                "focinho": { posX: 459, posY: 384 },
                                "anteriores": { posX: 102, posY: 296 },
                                "posteriores": { posX: 646, posY: 296 }
                            }

                            contexto.drawImage(img, posicao[classe].posX, posicao[classe].posY - 80);
                        }));
                    }, function () {
                        Promise.all(promessasImagens).then(function () {
                            solucao(canvas.toDataURL("image/png"));
                        });
                    });
                });
            });
        }
    }
    window.Particularidade = Particularidade;
})();
