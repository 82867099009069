﻿(function () {
  window.Requisicao = {
      dadosAnimalRequisicao: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Número: ", bold: true }, { text: dados.numeroBrinco }], lineHeight: 1.3 });
          primeiraColuna.push({ text: [{ text: "Nome: ", bold: true }, { text: dados.nomeAnimal }], lineHeight: 1.3 });
          primeiraColuna.push({ text: [{ text: "Espécie: ", bold: true }, { text: dados.especie }], lineHeight: 1.3 });
          primeiraColuna.push({ text: [{ text: "Local onde se encontra: ", bold: true }, { text: dados.localAnimal }], lineHeight: 1.3 });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "Registro Nº/Marca: ", bold: true }, { text: dados.numeroAssociacaoRaca }], lineHeight: 1.3 });
          segundaColuna.push({ text: [{ text: "Sexo: ", bold: true }, { text: dados.sexo }], lineHeight: 1.3 });
          segundaColuna.push({ text: [{ text: "Raça: ", bold: true }, { text: dados.raca }], lineHeight: 1.3 });

          var terceiraColuna = [];
          terceiraColuna.push({ text: [{ text: "Idade: ", bold: true }, { text: dados.idade }], lineHeight: 1.3 });

          if (dados.sexo === "FÊMEA")
              terceiraColuna.push({ text: [{ text: "Estado gestacional: ", bold: true }, { text: dados.castradoPrenha }], lineHeight: 1.3 });
          else
              terceiraColuna.push({ text: [{ text: " ", bold: true }, { text: " " }], lineHeight: 1.3 });

          terceiraColuna.push({ text: [{ text: "Nº de equídeos existentes: ", bold: true }, { text: dados.qtdeEquideoExistente }], lineHeight: 1.3 });
          terceiraColuna.push({ text: [{ text: "Município: ", bold: true }, { text: dados.localAnimalMunicipio }], lineHeight: 1.3 });

          return {
              margin: [0, 0],
              stack: [{
                  columns: [
                      { stack: primeiraColuna, width: "*" },
                      { stack: segundaColuna, width: "*" },
                      { stack: terceiraColuna, width: "*" }
                  ]
              }]
          };
      },
      dadosLaboratorioCredenciamento: function (dados) {
          return { text: [{ text: "Portaria de Credenciamento Nº ", bold: true }, dados.portariaLaboratorio], lineHeight: 1.3 };
      },
      dadosLaboratorioPrincipal: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Nome: ", bold: true }, dados.nomeLaboratorio], lineHeight: 1.3 });
          primeiraColuna.push({ text: [{ text: "Endereço: ", bold: true }, dados.enderecoLaboratorio], lineHeight: 1.3 });
          primeiraColuna.push({ text: [{ text: "E-mail: ", bold: true }, dados.emailLaboratorio], lineHeight: 1.3 });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "CNPJ: ", bold: true }, dados.cnpjLaboratorio], lineHeight: 1.3 });
          segundaColuna.push({ text: [{ text: "Município: ", bold: true }, dados.municipioLaboratorio], lineHeight: 1.3 });
          segundaColuna.push({ text: [{ text: "Telefone: ", bold: true }, dados.telefoneLaboratorio], lineHeight: 1.3 });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: '66,66%' },
                  { stack: segundaColuna, width: '33,33%' }
              ]
          };
      },
      dadosPropriedade: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Nome: ", bold: true }, dados.estabelecimento], lineHeight: 1.3 });
          primeiraColuna.push({ text: [{ text: "Via de Acesso: ", bold: true }, dados.viaAcesso || ""], lineHeight: 1.3 });
          primeiraColuna.push({ text: [{ text: "Coordenadas: ", bold: true }, dados.coordenadas || ""], lineHeight: 1.3 });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "Inscrição: ", bold: true }, dados.inscricao], lineHeight: 1.3 });
          segundaColuna.push({ text: [{ text: "Município: ", bold: true }, dados.municipio, "/", dados.uf], lineHeight: 1.3 });
          segundaColuna.push({ text: [{ text: "Classificação: ", bold: true }, dados.classificacaoRequisicaoNome], lineHeight: 1.3 });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: '66,66%' },
                  { stack: segundaColuna, width: '33,33%' }
              ]
          };
      },
      dadosProprietarioRequisicao: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Nome: ", bold: true }, dados.nomeProdutor], lineHeight: 1.3 });
          primeiraColuna.push({ text: [{ text: "Endereço: ", bold: true }, dados.endereco], lineHeight: 1.3 });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "CPF/CNPJ: ", bold: true }, dados.cpfcnpjProdutor], lineHeight: 1.3 });
          segundaColuna.push({ text: [{ text: "Telefone: ", bold: true }, dados.telefone], lineHeight: 1.3 });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: '66,66%' },
                  { stack: segundaColuna, width: '33,33%' }
              ]
          };
      },
      dadosRequisicao: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: dados.exameAie ? "Nº do Exame: " : "Nº Sequencial do Formulário: ", bold: true }, dados.numeroRequisicao], lineHeight: 1.3 });

          var segundaColuna = [];
          if (!dados.exameAie)
              segundaColuna.push({ text: [{ text: "Finalidade do Exame: ", bold: true }, dados.finalidadeTransitoNome], lineHeight: 1.3, alignment: "right" });

          return {
              margin: [0, 0, 15, 0],
              columns: [
                  { stack: primeiraColuna, width: "*" },
                  { stack: segundaColuna, width: "*" }
              ]
          };
      },
      dadosResenha: function (dados) {
          var partPelagem = dados.pelagemAnimal != null ? dados.pelagemAnimal.pelagemNome : "";

          if (dados.pelagemAnimal != null && !LaboratorioUtil.EhVazioOuNulo(dados.pelagemAnimal.pelagemVariedadeNome))
              partPelagem += " - " + dados.pelagemAnimal.pelagemVariedadeNome;

          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Nº RESENHA: ", bold: true }, dados.ultimaResenhaID] });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "Pelagem: ", bold: true }, partPelagem] });

          return {
              fontSize: 7,
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: "*" },
                  { stack: segundaColuna, width: "*" }
              ]
          };
      },
      medicoVeterinarioEndereco: function (dados) {

          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Endereço: ", bold: true }, { text: dados.enderecoVeterinario }], lineHeight: 1.3 });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "CRMV nº/UF: ", bold: true }, { text: dados.crmvVeterniario }], lineHeight: 1.3 });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: '66,66%' },
                  { stack: segundaColuna, width: '33,33%' }
              ]
          };
      },
      medicoVeterinarioEmailTelefone: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "E-mail: ", bold: true }, { text: dados.emailVeterinario }], lineHeight: 1.3 });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "Telefone: ", bold: true }, { text: dados.telefoneVeterinario }], lineHeight: 1.3 });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: '66,66%' },
                  { stack: segundaColuna, width: '33,33%' }
              ]
          };
      },
      medicoVeterinarioHabilitacao: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Port. Habilitação IAGRO - PNSE: ", bold: true }, { text: dados.habilitacaoPNSEVeterinario }], lineHeight: 1.3 });

          var segundaColuna = [];

          if (!dados.exameAie)
              segundaColuna.push({ text: [{ text: "Port. Habilitação MORMO: ", bold: true }, { text: dados.habilitacaoMormoVeterinario }], lineHeight: 1.3 });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: "*" },
                  { stack: segundaColuna, width: "*" }
              ]
          };
      },
      medicoVeterinarioPrincipal: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Nome: ", bold: true }, { text: dados.nomeVeterniario }], lineHeight: 1.3 });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "CPF: ", bold: true }, { text: dados.cpfVeterniario }], lineHeight: 1.3 });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: '66,66%' },
                  { stack: segundaColuna, width: '33,33%' }
              ]
          };
      },
      requisitanteLaboratorio: function (dados) {
          return {
              margin: [0, 0, 0, 0],
              table: {
                  fontSize: 9,
                  headerRows: 1,
                  widths: ["44%", "39%", "15%"],
                  //body: corpoTabela
                  body: [
                      //HEADER
                      [{ text: "REQUISITANTE", fillColor: '#dddddd', alignment: "center", bold: true },
                      { stack: [{ text: "LABORATÓRIO", fillColor: '#dddddd', alignment: "center", bold: true }, ""], fillColor: '#dddddd' },
                      { text: "AUTENTICIDADE", fillColor: '#dddddd', alignment: "center", bold: true },],

                      //BODY
                      [
                          { rowSpan: 2, text: "A colheita da amostra e resenha deste animal são de minha responsabilidade. ", alignment: "left", bold: true, border: [true, false, true, false] },
                          { text: [{ text: "Antígeno (Marca ou Nome): ", bold: true }, "======================="], alignment: "left" },
                          {
                              rowSpan: 6,
                              border: [true, false, true, true],

                              margin: [0, 13, 0, 0],
                              stack: [{
                                  image: dados.qrCode,
                                  alignment: "center",
                                  margin: [0, 0, 0, 0],
                                  width: 80,
                                  height: 74
                              }]
                          }
                      ],
                      [
                          "",{ text: [{ text: "Nº da Partida: ", bold: true }, "=================================="] },""
                      ],
                      [
                          {
                              rowSpan: 2,
                              border: [true, false, true, false],
                              margin: [0, 5, 0, 0],
                              stack: [{ text: dados.municipio + "/" + dados.uf + ",  " + dados.dataColetaPorExtenso, alignment: "left", decoration: 'underline' }, {
                                  text: "Município e data da colheita", alignment: "left", bold: true
                              }]

                          }, { text: [{ text: "Data do Resultado do Exame: ", bold: true }, "======================"] },
                          ""
                      ],
                      ["", { text: [{ text: "Resultado: ", bold: true }, { text: dados.portariaResultado, bold: true, italics: true, fontSize: 8 }] }, ""],
                      [
                          {
                              rowSpan: 2,
                              border: [true, false, true, true],
                              margin: [0, 0, 0, 0],
                              stack: [{ text: dados.nomeVeterniario, alignment: "center", bold: true, border: [true, false, true, false] },
                              { text: "Médico(a) Veterinário(a) Requisitante", alignment: "center", border: [true, false, true, false] },
                              { text: "ASSINADO ELETRÔNICAMENTE NO SISTEMA E-SANIAGRO ATRAVÉS DE LOGIN E SENHA", alignment: "center", italics: true, fontSize: 6, border: [true, true, true, false], margin: [0, 0, 0, 0] }
                              ]
                          },
                          { text: [{ text: "Data de Validade: ", bold: true }, "==============================="] },
                          ""
                      ],
                      //ASSINATURAS
                      [
                          { stack: ["", ""], border: [true, false, true, true] },
                          { text: [{ text: "Assinatura e Carimbo do(a) Responsável Técnico(a): ", bold: true }, "=============================================="], margin: [0, 0, 0, 0] }, ""
                      ]
                  ],
                  layout: 'noBorders'
              }
          };
      },
      resenhaDescritiva: function (dados) {
          var particularidades = dados.listaParticularidadeDescritiva;
          var corpoDescricao = [];
          var partDescricao = "";
          particularidades.forEach(function (item) {

              partDescricao += item.desenhoAnimal + ": ";
              var part = [];
              item.particularidadesAnimal.forEach(function (elemento) {
                  part.push(elemento.descricao);
              });

              corpoDescricao.push({ text: [{ text: item.desenhoAnimal + ": ", bold: true }, { text: part.join(", ") }] });
          });

          if (!LaboratorioUtil.EhVazioOuNulo(dados.observacao))
              corpoDescricao.push({ text: [{ text: "Observação: ", bold: true }, { text: dados.observacao }] });

          return {
              fontSize: 7,
              margin: [0, 0, 10, 0],
              stack: corpoDescricao
          };
      },
      resenhaGrafica: function (imgBase64, versao) {
          return {
              image: imgBase64,
              width: versao == 1 ? 450 : 550,
              height: versao == 1 ? 250 : 220,
              margin: [0, -10, 0, 5],
              alignment: "center"
          }
      }
  };
})();
