(function () {
    window.Certificado = {
        dadosAnimal: function (dados) {
            var primeiraColuna = [];
            primeiraColuna.push({ text: [{ text: "Número: ", bold: true }, { text: dados.numeroBrinco }], lineHeight: 1.3 });
            primeiraColuna.push({ text: [{ text: "Nome: ", bold: true }, { text: dados.nomeAnimal }], lineHeight: 1.3 });
            primeiraColuna.push({ text: [{ text: "Espécie: ", bold: true }, { text: dados.especie }], lineHeight: 1.3 });
            primeiraColuna.push({ text: [{ text: "Nº Passaporte: ", bold: true }, { text: dados.numeroPassaporte }], lineHeight: 1.3 });

            if (dados.numeroRequisicao != null)
                primeiraColuna.push({ text: [{ text: "Classificação: ", bold: true }, { text: dados.classificacaoRequisicaoNome }], lineHeight: 1.3 });

            if (!window.LaboratorioUtil.EhVazioOuNulo(dados.animalMae))
                primeiraColuna.push({ text: [{ text: "Mãe: ", bold: true }, { text: dados.animalMae }], lineHeight: 1.3 });

            var segundaColuna = [];
            segundaColuna.push({ text: [{ text: "Raça: ", bold: true }, { text: dados.raca }], lineHeight: 1.3 });
            segundaColuna.push({ text: [{ text: "Idade: ", bold: true }, { text: dados.idade }], lineHeight: 1.3 });
            if (dados.sexo === "FÊMEA")
                segundaColuna.push({ text: [{ text: "Sexo: ", bold: true }, { text: dados.sexo }, " / ", { text: "Prenhez: ", bold: true }, { text: dados.prenhez }], lineHeight: 1.3 });
            else
                segundaColuna.push({ text: [{ text: "Sexo: ", bold: true }, { text: dados.sexo }], lineHeight: 1.3 });


            segundaColuna.push({ text: [{ text: "Nº Chip: ", bold: true }, { text: dados.numeroChip }], lineHeight: 1.3 });
            if (dados.numeroRequisicao != null)
                segundaColuna.push({ text: [{ text: "Local onde se encontra: ", bold: true }, { text: dados.localAnimal }], lineHeight: 1.3 });

            var terceiraColuna = [];
            terceiraColuna.push({ text: [{ text: "Situação: ", bold: true }, { text: dados.situacao + " - " + dados.situacaoEm }], lineHeight: 1.3 });
            terceiraColuna.push({ text: [{ text: "Pelagem: ", bold: true }, { text: dados.pelagemAnimal != null ? dados.pelagemAnimal.pelagemNome : "" }], lineHeight: 1.3 });
            if (dados.pelagemAnimal != null && !window.LaboratorioUtil.EhVazioOuNulo(dados.pelagemAnimal.pelagemVariedadeNome))
                terceiraColuna.push({ text: [{ text: "Subtipo Pelagem: ", bold: true }, { text: dados.pelagemAnimal.pelagemVariedadeNome }], lineHeight: 1.3 });

            terceiraColuna.push({ text: [{ text: "Nº Assoc. Raça/Marca: ", bold: true }, { text: dados.numeroAssRaca }], lineHeight: 1.3 });
            if (dados.pelagemAnimal != null && !window.LaboratorioUtil.EhVazioOuNulo(dados.pelagemAnimal.apalusaNome))
                terceiraColuna.push({ text: [{ text: "Apalusa: ", bold: true }, { text: dados.pelagemAnimal.apalusaNome }], lineHeight: 1.3 });
            var partPelagem = {};
            if (dados.pelagemAnimal != null && !window.LaboratorioUtil.EhVazioOuNulo(dados.pelagemAnimal.partPelagem))
                partPelagem = { text: [{ text: "Part. Pelagem: ", bold: true }, { text: dados.pelagemAnimal.partPelagem }], lineHeight: 1.3 };

            return {
                margin: [0, 0],
                stack: [{
                    columns: [
                        { stack: primeiraColuna, width: "*" },
                        { stack: segundaColuna, width: "*" },
                        { stack: terceiraColuna, width: "*" }
                    ]
                },
                    partPelagem
                ]
            };
        },
        dadosNumeroResenha: function (dados) {
            return { fontSize: 7, text: [{ text: "Nº RESENHA: ", bold: true }, dados.ultimaResenhaID || ''] };
        },
        dadosProprietario: function (dados) {
            var primeiraColuna = [];
            primeiraColuna.push({ text: [{ text: "Nome: ", bold: true }, dados.nomeProdutor], lineHeight: 1.3 });
            primeiraColuna.push({ text: [{ text: "Inscrição: ", bold: true }, dados.inscricao], lineHeight: 1.3 });
            primeiraColuna.push({ text: [{ text: "Telefone: ", bold: true }, dados.telefone==null?"":dados.telefone], lineHeight: 1.3 });

            var segundaColuna = [];
            segundaColuna.push({ text: [{ text: "CPF/CNPJ: ", bold: true }, dados.cpfcnpjProdutor], lineHeight: 1.3 });
            segundaColuna.push({ text: [{ text: "Estabelecimento: ", bold: true }, dados.estabelecimento], lineHeight: 1.3 });
            segundaColuna.push({ text: [{ text: "Município: ", bold: true }, dados.municipio, "/", dados.uf], lineHeight: 1.3 });


            return {
                margin: [0, 0, 10, 0],
                columns: [
                    { stack: primeiraColuna, width: "*" },
                    { stack: segundaColuna, width: "*" }
                ]
            };
        },
        dadosProprietarioEndereco: function (dados) {
            return { text: [{ text: "Endereço: ", bold: true }, dados.endereco], lineHeight: 1.3 };
        },
        fotos: function (dados) {
            var listaFotos = dados.listaFotoAnimal;

            var imagens = [];
            listaFotos.forEach(function (foto) {

                var tamanhoFoto = [90, 90];
                var larguraFoto = "15%";
                var alinhamentoFoto = "left";
                if (foto.tipoFotoAnimalID === 3 || foto.tipoFotoAnimalID === 4) {
                    tamanhoFoto = [160, 160];
                    larguraFoto = "35%";
                }

                imagens.push({
                    width: larguraFoto,
                    alignment: "left",
                    stack: [
                        { text: foto.tipoFotoAnimal },
                        {
                            image: foto.imagem, fit: tamanhoFoto
                        }
                    ]
                });
            });

            return {
                fontSize: 7,
                margin: [0, 0, 0, 0],
                columns: imagens
            }
        },
        fotosMarcas: function (dados) {
            var listaFotos = dados.listaFotoMarcasAnimal;
            if (dados.listaFotoMarcasAnimal.length < 1)
                return {};

            var imagens = [];
            for (var i = 0; i < 4; i++) {
                if (listaFotos[i] != null) {
                    imagens.push({
                        alignment: "left",
                        stack: [
                            { text: listaFotos[i].tipoFotoAnimal },
                            { image: listaFotos[i].imagem, fit: [90, 90, 90, 90] }
                        ]
                    });
                } else {
                    imagens.push({
                        alignment: "left",
                        stack: [
                            { text: "" }
                        ]
                    });
                }
            }

            return {
                fontSize: 7,
                stack: [{
                    margin: [0, 10, 0, 0],
                    columns: imagens
                }]
            }
        },
        resenhaDescritiva: function (dados) {
            var particularidades = dados.listaParticularidadeDescritiva;
            var corpoDescricao = [];
            var partDescricao = "";
            particularidades.forEach(function (item) {

                partDescricao += item.desenhoAnimal + ": ";
                var part = [];
                item.particularidadesAnimal.forEach(function (elemento) {
                    part.push(elemento.descricao);
                });

                corpoDescricao.push({ text: [{ text: item.desenhoAnimal + ": ", bold: true }, { text: part.join(", ") }] });
            });

            if (!LaboratorioUtil.EhVazioOuNulo(dados.observacao))
                corpoDescricao.push({ text: [{ text: "Observação: ", bold: true }, { text: dados.observacao }] });

            return {
                fontSize: 7,
                margin: [0, 0, 10, 0],
                stack: corpoDescricao
            };
        },
        resenhaGrafica: function (imgBase64, versao) {
            return {
                image: imgBase64,
                width: versao == 1 ? 450 : 550,
                height: versao == 1 ? 250 : 220,
                margin: [0, -10, 0, 5],
                alignment: "center"
            }
        }
    };
})();
