var vmPdfPublicoAnimal = function (data) {
    var self = this;
    self.tituloCertificado = data.TituloCertificado;
    self.tituloRelatorioEnsaio = data.TituloRelatorioEnsaio;
    self.requisicaoEletronica = data.RequisicaoEletronica;
    self.mensagemDeclaracao = data.MensagemDeclaracao;
    self.mensagemDeclaracaoLaudo = data.MensagemDeclaracaoLaudo;
    self.inscricao = data.IECPFCNPJ || data.Iecpfcnpj;
    self.numeroBrinco = data.NumeroBrinco;
    self.situacao = data.SituacaoNome;
    self.situacaoEm = data.SituacaoEm;
    self.especie = data.Especie;
    self.raca = data.Raca;
    self.nomeAnimal = data.NomeAnimal;
    self.municipio = data.Municipio;
    self.uf = data.Uf;
    self.estabelecimento = data.Estabelecimento;
    self.nomeProdutor = data.NomeProdutor;
    self.cpfcnpjProdutor = data.CPFCNPJProdutor || data.CpfcnpjProdutor;
    self.sexo = data.Sexo;
    self.castrado = data.Castrado;
    self.prenha = data.Prenha;
    self.usuarioLogado = data.UsuarioLogado;
    self.idade = data.Idade;
    self.endereco = data.Endereco;
    self.telefone = data.Telefone;
    self.observacao = data.Observacao;
    self.localAnimal = data.LocalAnimal;
    self.classificacaoRequisicaoNome = data.ClassificacaoRequisicaoNome;
    self.qtdeEquideoExistente = data.QtdeEquideoExistente;
    self.viaAcesso = data.ViaAcesso;
    self.coordenadas = data.Coordenadas;
    self.localAnimalMunicipio = data.LocalAnimalMunicipio;

    self.governo = data.Governo;
    self.secretaria = data.Secretaria;
    self.agencia = data.Agencia;
    self.imagemLogo = data.ImagemLogo;

    self.nomeVeterniario = data.NomeVeterinario;
    self.cpfVeterniario = data.CpfVeterinario;
    self.crmvVeterniario = data.CrmvVeterinario;
    self.enderecoVeterinario = data.EnderecoVeterinario;
    self.municipioVeterinario = data.MunicipioVeterinarioNome;
    self.telefoneVeterinario = data.TelefoneVeterinario;
    self.emailVeterinario = data.EmailVeterinario;
    self.habilitacaoPNSEVeterinario = data.HabilitacaoPNSEVeterinario;
    self.habilitacaoMormoVeterinario = data.HabilitacaoMormoVeterinario;

    self.nomeLaboratorio = data.NomeLaboratorio;
    self.cnpjLaboratorio = data.CnpjLaboratorio;
    self.portariaLaboratorio = data.PortariaLaboratorio;
    self.enderecoLaboratorio = data.EnderecoLaboratorio;
    self.municipioLaboratorio = data.MunicipioLaboratorio;
    self.telefoneLaboratorio = data.TelefoneLaboratorio;
    self.emailLaboratorio = data.LaboratorioEmail;
    self.crl = data.Crl;
    self.formPop = data.FormPop;
    self.informacoesAdicionais = data.InformacoesAdicionais;

    self.numeroRequisicao = data.NumeroRequisicao;
    self.numeroRequisicaoLaudo = data.NumeroRequisicaoLaudo;
    self.dataRequisicao = data.DataRequisicao;
    self.laudoExameID = data.LaudoExameID;
    self.arquivoAssinado = data.ArquivoAssinado;

    self.exameAie = data.ExameAie;
    self.qrCode = data.QrCode;
    self.qrCodeLaudoExame = data.QrCodeLaudoExame;
    self.finalidadeTransitoNome = data.FinalidadeTransitoNome;
    self.animalMae = data.AnimalMae;
    self.ultimaResenhaID = data.UltimaResenhaID;
    self.portariaResultado = data.ExameAie ? "Relatório de ensaio emitido conforme Instrução Normativa Nº 52/2018" : "Relatório de ensaio emitido conforme Portaria Nº 35/2018";
    self.legislacaoRelatorioEnsaio = data.LegislacaoRelatorioEnsaio;
    self.laudoExameAIE = data.LaudoExameAIE;
    self.laudoExameMormo = data.LaudoExameMormo;

    self.numeroPassaporte = data.NumeroPassaporte;
    self.numeroChip = data.NumeroChip;
    self.numeroAssociacaoRaca = data.NumeroAssociacaoRaca;
    self.versao = data.Versao;

    if (data.PelagemAnimal != null) {
        self.pelagemAnimal = new vmPelagemAnimal(data.PelagemAnimal);
    }

    self.matriz = data.Matriz;
    self.dataColeta = data.DataColeta;
    self.dataColetaPorExtenso = data.DataColetaPorExtenso;
    self.dataRecepcao = data.DataRecepcao;
    self.numeroRelatorioEnsaio = data.NumeroRelatorioEnsaio;
    self.numeroRegistroAmostra = data.NumeroRegistroAmostra;
    self.numeroLacre = data.NumeroLacre;

    self.localEDataEmissao = data.LocalEDataEmissao;

    self.listaFotoAnimal = [];
    self.listaFotoMarcasAnimal = [];
    self.listaParticularidadeAnimal = [];
    self.listaParticularidadeAnimaljson = [];
    self.listaParticularidadeDescritiva = [];
    self.listaRelatorioEnsaio = [];

    if (data.ListaFotoAnimal != null) {
        data.ListaFotoAnimal.forEach(function (ea) {
            self.listaFotoAnimal.push(new vmFotoAnimal(ea));
        });
    }

    if (data.ListaFotoMarcasAnimal != null) {
        data.ListaFotoMarcasAnimal.forEach(function (ea) {
            self.listaFotoMarcasAnimal.push(new vmFotoAnimal(ea));
        });
    }

    if (data.ListaRelatorioEnsaio != null) {
        data.ListaRelatorioEnsaio.forEach(function (ea) {
            self.listaRelatorioEnsaio.push(new vmRelatorioEnsaio(ea));
        });
    }

    if (data.ListaParticularidadeAnimal != null) {
        data.ListaParticularidadeAnimal.forEach(function (ea) {
            self.listaParticularidadeAnimal.push(new vmParticularidadeAnimal(ea));
            self.listaParticularidadeAnimaljson.push({
                desenhoId: ea.DesenhoID,
                particularidadeId: ea.ParticularidadeID,
                desenhoAnimalRegiaoId: ea.DesenhoAnimalRegiaoID,
                eixoX: ea.EixoX,
                eixoY: ea.EixoY,
                radiante: ea.Radiante,
                lado: ea.Lado,
                posicao: ea.Posicao
            });
        });
    }

    if (data.ListaParticularidadeDescritiva != null) {
        data.ListaParticularidadeDescritiva.forEach(function (ea) {
            self.listaParticularidadeDescritiva.push(new vmParticularidadeDescritiva(ea));
        });
    }

    self.castradoPrenha = "";
    self.prenhez = "";
    if (self.castrado) {
        self.castradoPrenha = " - " + (self.sexo === "MACHO" ? "Castrado" : "");
    }

    if (self.sexo === "FÊMEA") {
        self.castradoPrenha = self.prenha ? "PRENHE" : "NÃO PRENHE";
        self.prenhez = self.prenha ? "Sim" : "Não";
    }

    self.resultado = data.Resultado;
    self.dataValidadeAIE = data.DataValidadeAIE;
    self.dataValidadeMormo = data.DataValidadeMormo;
    self.dataColeta = data.DataColeta;
};

var vmFotoAnimal = function (data) {
    var self = this;

    self.animalID = data.AnimalID;
    self.fotoAnimalID = data.FotoAnimalID;
    self.imagem = data.StringImagem;
    self.miniatura = data.StringMiniatura;
    self.tipoFotoAnimal = data.TipoFotoAnimal;
    self.tipoFotoAnimalID = data.TipoFotoAnimalID;
}

var vmRelatorioEnsaio = function (data) {
    var self = this;
    self.nomeMetodoEnsaio = data.NomeMetodoEnsaio;
    self.nomeTipoAtestado = data.NomeTipoAtestado;
    self.tipoAtestadoID = data.TipoAtestadoID;
    self.kitElisa = data.KitElisa;
    self.nomeComercial = data.NomeComercial;
    self.fabricante = data.Fabricante;
    self.partidaLote = data.PartidaLote;
    self.validade = data.DataValidade;
    self.dataInicial = data.DataInicial;
    self.dataFinal = data.DataFinal;
    self.resultado = data.Resultado;
}

var vmPelagemAnimal = function (data) {
    var self = this;
    self.tipoSexoID = data.TipoSexoID;
    self.castrado = data.Castrado;
    self.pelagemNome = data.PelagemNome;
    self.pelagemVariedadeNome = data.PelagemVariedadeNome;
    self.apatacado = data.Apatacado;
    self.salpicado = data.Salpicado;
    self.apalusaNome = data.ApalusaNome;
    self.celhado = data.Celhado;
    self.crinalvo = data.Crinalvo;
    self.rabicao = data.Rabicao;
    self.tronchaMochaDireita = data.TronchaMochaDireita;
    self.tronchaMochaEsquerda = data.TronchaMochaEsquerda;
    self.corOlhoDireito = data.CorOlhoDireito;
    self.corOlhoEsquerdo = data.CorOlhoEsquerdo;
    self.partPelagem = data.PartPelagem;
}

var vmParticularidadeDescritiva = function (data) {
    var self = this;
    self.desenhoAnimal = data.DesenhoAnimal;
    self.particularidadesAnimal = [];

    data.ParticularidadesAnimal.forEach(function (element) {
        self.particularidadesAnimal.push({ descricao: element });
    });
};

var vmParticularidadeAnimal = function (data) {
    var self = this;
    self.desenhoId = data.DesenhoID;
    self.particularidadeId = data.ParticularidadeID;
    self.eixoX = data.EixoX;
    self.eixoY = data.EixoY;
    self.lado = data.Lado;
};
