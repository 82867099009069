﻿var gerarLaudoExame = function (animal) {
    if (animal == null)
        return "";

    var objRelatorio = [];
    var Laudo = window.Laudo;
    var Comum = window.Comum;

    if (animal.numeroRequisicaoLaudo !== null && animal.numeroRequisicaoLaudo !== "") {
        var tituloCertificado = animal.tituloRelatorioEnsaio;

        objRelatorio.push([
            Comum.cabecalho(animal, "", animal.qrCodeLaudoExame),
            { text: tituloCertificado, bold: true, lineHeight: 1.1, alignment: "center", fontSize: 10, margin: [-27, -48, 0, 0] },
            Comum.linhaHorizontal(true, -11, 0, 0, 5),
            Laudo.informacoesRequisicao(animal),
            Comum.linhaHorizontal(true, -11, 0, 0, 2),
            { text: "PROPRIETÁRIO DO ANIMAL", alignment: "left", lineHeight: 1.1, bold: true, margin: [0, 0, 0, 0] },
            Comum.linhaHorizontal(true, -11, 0, 0, 5),
            Comum.dadosProprietario(animal),
            Comum.linhaHorizontal(true, -11, 0, 0, 2),
            { text: "MÉDICO VETERINÁRIO REQUISITANTE RESPONSÁVEL PELA COLETA", alignment: "left", lineHeight: 1.1, bold: true, margin: [0, 0, 0, 0] },
            Comum.linhaHorizontal(true, -11, 0, 0, 5),
            Laudo.medicoVeterinarioPrincipal(animal),
            Laudo.medicoVeterinarioEndereco(animal),
            Laudo.medicoVeterinarioEmailTelefone(animal),
            Laudo.medicoVeterinarioHabilitacao(animal),
            Comum.linhaHorizontal(true, -11, 0, 0, 2),
            { text: "ANIMAL", alignment: "left", lineHeight: 1.1, bold: true, margin: [0, 0, 0, 0] },
            Comum.linhaHorizontal(true, -11, 0, 0, 5),
            Laudo.dadosAnimalNumero(animal),
            Laudo.dadosAnimal(animal),
            Comum.linhaHorizontal(true, -11, 0, 0, 2),
            { text: "AMOSTRA", alignment: "left", lineHeight: 1.1, bold: true, margin: [0, 0, 0, 0] },
            Comum.linhaHorizontal(true, -11, 0, 0, 5),
            Laudo.amostraMatriz(animal),
            Laudo.amostraData(animal),
            Laudo.amostraMetodo(animal),
            Laudo.amostraDataInicial(animal),
            Comum.linhaHorizontal(true, -11, 0, 0, 2)
        ]);

        animal.listaRelatorioEnsaio.forEach(function (relatorioEnsaio) {
            var nomeKit = relatorioEnsaio.nomeMetodoEnsaio + " " + relatorioEnsaio.nomeTipoAtestado;
            objRelatorio.push([
                { text: "KIT " + nomeKit, alignment: "left", lineHeight: 1.1, bold: true, margin: [0, 0, 0, 0] },
                Laudo.kits(relatorioEnsaio),
                Comum.linhaHorizontal(true, -11, 0, 0, 2)
            ]);
        });

        objRelatorio.push([
            { text: "RESULTADO", alignment: "left", lineHeight: 1.1, bold: true, margin: [0, 0, 0, 0] }
        ]);

        var tipoAtestados = [120, 125];

        tipoAtestados.forEach(function (tipoAtestado) {
            var relatorios = animal.listaRelatorioEnsaio.filter(function (x) { return x.tipoAtestadoID === tipoAtestado; });

            if (relatorios.length > 0)
                objRelatorio.push([
                    Laudo.resultado(relatorios)
                ]);
        });

        objRelatorio.push([
            Comum.linhaHorizontal(true, -11, 0, 0, 5),
            Laudo.validadeInformacoesAdicionais(animal),
            Laudo.local(animal),
            Laudo.assinatura(animal),
            Laudo.dadosAdicionais(animal)
        ]);
    };

    return {
        fontSize: 8,
        stack: objRelatorio
    };
};
