﻿(function () {
    var ParticularidadeSVG = function (localHtml) {
        if (!(this instanceof ParticularidadeSVG)) {
            return new ParticularidadeSVG(localHtml);
        }
        let _this = this;
        _this.localHtml = localHtml;
        _this.divBase = document.createElementNS("http://www.w3.org/1999/xhtml", "div");
        _this.pastaImagem = root + "assets/";
        _this.pastaParticularidades = "particularidades/";
        _this.pastaBase = "base/v2/";
        _this.formato = ".png";
        
        _this.contexto = {};
        _this.canvas = {};

        _this.ECategoria = {
            CABECA: 1,
            FOCINHO: 2,
            GERAL: 3,
            MEMBRO: 4,
            PESCOCO: 5,
            TRONCO: 6
        };

        _this.EDesenho = {
            LATERAL_DIREITA: 1,
            LATERAL_ESQUERDA: 2,
            CABECA: 3,
            PESCOCO: 4,
            FOCINHO: 5,
            MEMBROS_ANTERIORES: 6,
            MEMBROS_POSTERIORES: 7
        };

        _this.EMembro = {
            ANTERIOR: 1,
            POSTERIOR: 2
        }

        _this.EParticularidade = {
            ESPIGA: "ESPIGA",
            CALCADO: "CALÇADO",
            COROA: "COROA"
        };

        _this.desenhos = [
            { desenhoId: 1, imagem: "LateralDireitaCavalo", largura: 400, altura: 351, classe: "lado-direito-svg", proporcao: 1.15, ajuste: [0, 0, 1, 1] },
            { desenhoId: 2, imagem: "LateralEsquerdaCavalo", largura: 400, altura: 351, classe: "lado-esquerdo-svg", proporcao: 1.15, ajuste: [0, 0, 1, 1] },
            { desenhoId: 3, imagem: "FrenteCabecaCavalo", largura: 200, altura: 270, classe: "cabeca-svg", proporcao: 1.15, ajuste: [0, 0, 1, 0.9] },
            { desenhoId: 4, imagem: "InferiorPescocoCavalo", largura: 192, altura: 320, classe: "pescoco-svg", proporcao: 1.05, ajuste: [1, 47, 0.95, 0.83] },
            { desenhoId: 5, imagem: "FocinhoCavalo", largura: 150, altura: 183, classe: "focinho-svg", proporcao: 0.6, ajuste: [-8, 0, 1, 1] },
            { desenhoId: 6, imagem: "MembrosAnterioresCavalo", largura: 185, altura: 300, classe: "anteriores-svg", proporcao: 0.65, ajuste: [0, 40, 0.98, 0.84] },
            { desenhoId: 7, imagem: "MembrosPosterioresCavalo", largura: 185, altura: 300, classe: "posteriores-svg", proporcao: 0.75, ajuste: [0, 40, 0.95, 0.84] }
        ];

        //particularidades
        _this.tipoParticularidades = [
            //CABEÇA
            { particularidadeId: 16, nome: "CORDÃO", dimensao: null, imagem: "cordao", pasta: "cabeca/v2/", multiplicador: 0.86, offset: [-1, 17], lado: false, ehFixa: true, categoriaId: _this.ECategoria.CABECA },
            { particularidadeId: 17, nome: "CORDÃO INTERROMPIDO", dimensao: null, imagem: "cordao_interrompido", pasta: "cabeca/v2/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false, ehFixa: true, categoriaId: _this.ECategoria.CABECA },
            { particularidadeId: 22, nome: "ESTRELA", dimensao: null, imagem: "estrela", pasta: "cabeca/v2/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false, ehFixa: true, categoriaId: _this.ECategoria.CABECA },
            { particularidadeId: 23, nome: "ESTRELA EM CORAÇÃO", dimensao: null, imagem: "estrela_em_coracao", pasta: "cabeca/v2/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false, ehFixa: true, categoriaId: _this.ECategoria.CABECA },
            { particularidadeId: 24, nome: "ESTRELA EM LOSANGO", dimensao: null, imagem: "estrela_em_losango", pasta: "cabeca/v2/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false, ehFixa: true, categoriaId: _this.ECategoria.CABECA },
            { particularidadeId: 25, nome: "ESTRELA EM MEIA LUA", dimensao: null, imagem: "estrela_em_meia_lua", pasta: "cabeca/v2/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false, ehFixa: true, categoriaId: _this.ECategoria.CABECA },
            { particularidadeId: 26, nome: "ESTRELA EM TRIÂNGULO", dimensao: null, imagem: "estrela_em_triangulo", pasta: "cabeca/v2/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false, ehFixa: true, categoriaId: _this.ECategoria.CABECA },
            { particularidadeId: 27, nome: "ESTRELA EM U", dimensao: null, imagem: "estrela_em_u", pasta: "cabeca/v2/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false, ehFixa: true, categoriaId: _this.ECategoria.CABECA },
            { particularidadeId: 29, nome: "FILETE", dimensao: null, imagem: "filete", pasta: "cabeca/v2/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false, ehFixa: true, categoriaId: _this.ECategoria.CABECA },
            { particularidadeId: 30, nome: "FILETE INTERROMPIDO", dimensao: null, imagem: "filete_interrompido", pasta: "cabeca/v2/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false, ehFixa: true, categoriaId: _this.ECategoria.CABECA },
            { particularidadeId: 31, nome: "FRENTE ABERTA", dimensao: null, imagem: "frente_aberta", pasta: "cabeca/v2/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false, ehFixa: true, categoriaId: _this.ECategoria.CABECA },
            { particularidadeId: 35, nome: "LUZEIRO", dimensao: null, imagem: "luzeiro", pasta: "cabeca/v2/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false, ehFixa: true, categoriaId: _this.ECategoria.CABECA },
            { particularidadeId: 36, nome: "MALACARA", dimensao: null, imagem: "malacara", pasta: "cabeca/v2/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false, ehFixa: true, categoriaId: _this.ECategoria.CABECA },
            { particularidadeId: 52, nome: "LUZEIRO COM INÍCIO DE FILETE", dimensao: null, imagem: "luzeiro_com_inicio_de_filete", pasta: "cabeca/v2/", multiplicador: [0.86, 0.81], offset: [-1, 5], lado: false, ehFixa: true, categoriaId: _this.ECategoria.CABECA },

            //FOCINHO
            { particularidadeId: 4, nome: "BEBE EM BRANCO", dimensao: null, imagem: "bebe_em_branco", pasta: "focinho/v2/", multiplicador: [0.82, 0.72], offset: [0, -28], lado: false, ehFixa: true, categoriaId: _this.ECategoria.FOCINHO },
            { particularidadeId: 5, nome: "BETA", dimensao: null, imagem: "ladre", pasta: "focinho/v2/", multiplicador: [0.82, 0.72], offset: [0, -25], lado: false, ehFixa: true, categoriaId: _this.ECategoria.FOCINHO },
            { particularidadeId: 6, nome: "BOCALVO", dimensao: null, imagem: "bocalvo", pasta: "focinho/v2/", multiplicador: [0.82, 0.72], offset: [0, -24], lado: false, ehFixa: true, categoriaId: _this.ECategoria.FOCINHO },
            { particularidadeId: 57, nome: "BEBE EM BRANCO INFERIOR", dimensao: null, imagem: "bebe_em_branco_inferior", pasta: "focinho/v2/", multiplicador: [0.82, 0.72], offset: [0, -25], lado: false, ehFixa: true, categoriaId: _this.ECategoria.FOCINHO },
            { particularidadeId: 58, nome: "BEBE EM BRANCO SUPERIOR", dimensao: null, imagem: "bebe_em_branco_superior", pasta: "focinho/v2/", multiplicador: [0.82, 0.72], offset: [0, -25], lado: false, ehFixa: true, categoriaId: _this.ECategoria.FOCINHO },

            //GERAL
            { particularidadeId: 15, nome: "CICATRIZ", dimensao: null, imagem: "cicatriz", pasta: "geral/", multiplicador: 0.8, offset: [10, 5], lado: false, ehFixa: false, categoriaId: _this.ECategoria.GERAL },
            { particularidadeId: 19, nome: "ESPIGA", dimensao: null, imagem: "espiga", pasta: "geral/", multiplicador: 0.8, offset: [8, 8], lado: false, ehFixa: false, categoriaId: _this.ECategoria.GERAL },
            { particularidadeId: 32, nome: "GOLPE DE LANÇA", dimensao: null, imagem: "golpe_de_lanca", pasta: "geral/", multiplicador: 0.8, offset: [5, 5], lado: false, ehFixa: false, categoriaId: _this.ECategoria.GERAL },
            { particularidadeId: 37, nome: "MARCA A FOGO", dimensao: null, imagem: "marca_a_fogo", pasta: "geral/", multiplicador: 1, offset: [10, 10], lado: false, ehFixa: false, categoriaId: _this.ECategoria.GERAL },
            { particularidadeId: 38, nome: "MARCA A FRIO", dimensao: null, imagem: "marca_a_frio", pasta: "geral/", multiplicador: 1, offset: [10, 10], lado: false, ehFixa: false, categoriaId: _this.ECategoria.GERAL },
            { particularidadeId: 40, nome: "PÊLOS BRANCOS", dimensao: null, imagem: "pelos_brancos", pasta: "geral/", multiplicador: 0.86, offset: [10, 10], lado: false, ehFixa: false, categoriaId: _this.ECategoria.GERAL },
            { particularidadeId: 43, nome: "RODOPIO", dimensao: null, imagem: "rodopio", pasta: "geral/", multiplicador: 0.75, offset: [8, 8], lado: false, ehFixa: false, categoriaId: _this.ECategoria.GERAL },
            { particularidadeId: 56, nome: "MANCHA", dimensao: null, imagem: "mancha", pasta: "geral/", multiplicador: [0.86, 0.81], offset: [8, 8], lado: false, ehFixa: false, categoriaId: _this.ECategoria.GERAL },

            //MEMBRO
            { particularidadeId: 1, nome: "ALTO CALÇADO", dimensao: { largura: 12, altura: 2 }, imagem: "calcado", pasta: "membros/", multiplicador: 0.5, offset: [12, 2], lado: false, ehFixa: false, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 2, nome: "ALTO CALÇADO - ARREGAÇADO", dimensao: { largura: 12, altura: 2}, imagem: "calcado", pasta: "membros/", multiplicador: 0.5, offset: [12, 2], lado: false, ehFixa: false, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 3, nome: "BAIXO CALÇADO", dimensao: { largura: 12, altura: 2}, imagem: "calcado", pasta: "membros/", multiplicador: 0.5, offset: [12, 2], lado: false, ehFixa: false, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 8, nome: "CALÇADO SOBRE COROA", dimensao: null, imagem: "calcado_sobre_coroa_posterior_{lado}", pasta: "membros/v2/", multiplicador: 0.44, offset: [0, 0], lado: true, ehFixa: true, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 9, nome: "ARMINHADO", dimensao: null, imagem: "arminhado", pasta: "membros/", multiplicador: 0.39, offset: [5, 5], lado: false, ehFixa: false, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 10, nome: "INCOMPLETO", dimensao: null, imagem: "calcado_imcompleto", pasta: "membros/", multiplicador: 1, offset: [12, 2], lado: false, ehFixa: false, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 11, nome: "CASCO BRANCO", dimensao: null, imagem: "casco_branco_{lado}_posterior", pasta: "membros/v2/", multiplicador: 0.44, offset: [0, 0], lado: true, ehFixa: true, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 13, nome: "CASCO RAJADO/MESCLADO", dimensao: null, imagem: "casco_rajado_mesclado_{lado}_posterior", pasta: "membros/v2/", multiplicador: 0.44, offset: [0, 0], lado: true, ehFixa: true, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 39, nome: "MÉDIO CALÇADO", dimensao: { largura: 12, altura: 2}, imagem: "calcado", pasta: "membros/", multiplicador: 0.5, offset: [12, 2], lado: false, ehFixa: false, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 48, nome: "ZEBRURAS", dimensao: null, imagem: "zebrado", pasta: "membros/", multiplicador: 0.3, offset: [8, 8], lado: false, ehFixa: false, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 49, nome: "ALTO CALÇADO INCOMPLETO", dimensao: { largura: 12, altura: 2}, imagem: "calcado_incompleto", pasta: "membros/", multiplicador: 0.5, offset: [12, 2], lado: false, ehFixa: false, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 50, nome: "BAIXO CALÇADO INCOMPLETO", dimensao: { largura: 12, altura: 2}, imagem: "calcado_incompleto", pasta: "membros/", multiplicador: 0.5, offset: [12, 2], lado: false, ehFixa: false, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 51, nome: "MÉDIO CALÇADO INCOMPLETO", dimensao: { largura: 12, altura: 2}, imagem: "calcado_incompleto", pasta: "membros/", multiplicador: 0.5, offset: [12, 2], lado: false, ehFixa: false, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 59, nome: "CASCO BRANCO", dimensao: null, imagem: "casco_branco_{lado}_anterior", pasta: "membros/v2/", multiplicador: 0.44, offset: [0, 0], lado: true, ehFixa: true, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 60, nome: "CASCO RAJADO/MESCLADO", dimensao: null, imagem: "casco_rajado_mesclado_{lado}_anterior", pasta: "membros/v2/", multiplicador: 0.44, offset: [0, 0], lado: true, ehFixa: true, categoriaId: _this.ECategoria.MEMBRO },
            { particularidadeId: 61, nome: "CALÇADO SOBRE COROA", dimensao: null, imagem: "calcado_sobre_coroa_anterior_{lado}", pasta: "membros/v2/", multiplicador: 0.44, offset: [0, 0], lado: true, ehFixa: true, categoriaId: _this.ECategoria.MEMBRO },

            //PESCOCO

            //TRONCO
            { particularidadeId: 7, nome: "BRAGADO", dimensao: null, imagem: "bragado", pasta: "tronco/", multiplicador: 0.43, offset: [10, 10], lado: false, ehFixa: false, categoriaId: _this.ECategoria.TRONCO },                     
            { particularidadeId: 28, nome: "FAIXA CRUCIAL", dimensao: null, imagem: "faixa_crucial_{lado}", pasta: "tronco/v2/", multiplicador: 0.43, offset: [0, 0], lado: true, ehFixa: true, categoriaId: _this.ECategoria.TRONCO },
            { particularidadeId: 34, nome: "LISTRA DE BURRO", dimensao: null, imagem: "listra_de_burro_{lado}", pasta: "tronco/v2/", multiplicador: 0.43, offset: [0, 0], lado: true, ehFixa: true, categoriaId: _this.ECategoria.TRONCO },
            { particularidadeId: 41, nome: "PISADURA", dimensao: null, imagem: "pisadura", pasta: "tronco/", multiplicador: 0.43, offset: [10, 10], lado: false, ehFixa: false, categoriaId: _this.ECategoria.TRONCO },
            { particularidadeId: 46, nome: "VESTÍGIO DE FAIXA CRUCIAL", dimensao: null, imagem: "vestigio_de_faixa_crucial_{lado}", pasta: "tronco/v2/", multiplicador: 0.43, offset: [0, 0], lado: true, ehFixa: true, categoriaId: _this.ECategoria.TRONCO }                        
        ];

        _this.espelhos = [
            // LATERAL DIREITA 
            { espelhoId: 1, particularidadeId: 4, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Bebe em Branco Direito', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/bebe_em_branco_direito', dimensao: null },
            { espelhoId: 2, particularidadeId: 57, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Bebe em Branco Inferior Direito', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/bebe_em_branco_inferior_direito', dimensao: null },
            { espelhoId: 3, particularidadeId: 58, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Bebe em Branco Superior Direito', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/bebe_em_branco_superior_direito', dimensao: null },
            { espelhoId: 4, particularidadeId: 5, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Ladre ou Beta Direito', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/ladre_ou_beta_direito', dimensao: null },
            { espelhoId: 5, particularidadeId: 6, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Bocalvo Direito', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/bocalvo_direito', dimensao: null },
            { espelhoId: 6, particularidadeId: 60, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Casco Rajado Anterior', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/casco_rajado_esquerdo_anterior', dimensao: null },
            { espelhoId: 7, particularidadeId: 13, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Casco Rajado Posterior', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/casco_rajado_esquerdo_posterior', dimensao: null },
            { espelhoId: 8, particularidadeId: 59, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Casco Branco Anterior', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/casco_branco_esquerdo_anterior', dimensao: null },
            { espelhoId: 9, particularidadeId: 11, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Casco Branco Posterior', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/casco_branco_esquerdo_posterior', dimensao: null },
            { espelhoId: 10, particularidadeId: 2, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Alto Calçado', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado', dimensao: { largura: 0, altura: 2} },
            { espelhoId: 11, particularidadeId: 3, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Baixo Calçado', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado', dimensao: { largura: 0, altura: 2} },
            { espelhoId: 12, particularidadeId: 39, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Médio Calçado', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado', dimensao: { largura: 0, altura: 2} },
            { espelhoId: 13, particularidadeId: 49, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Alto Calçado Incompleto', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado_incompleto', dimensao: { largura: 0, altura: 2} },
            { espelhoId: 14, particularidadeId: 50, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Baixo Calçado Incompleto', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado_incompleto', dimensao: { largura: 0, altura: 2} },
            { espelhoId: 15, particularidadeId: 51, desenhoId: _this.EDesenho.LATERAL_DIREITA, nome: 'Médio Calçado Incompleto', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado_incompleto', dimensao: { largura: 0, altura: 2} },

            // LATERAL ESQUERDA 
            { espelhoId: 16, particularidadeId: 4, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Bebe em Branco Esquerdo', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/bebe_em_branco_esquerdo', dimensao: null },
            { espelhoId: 17, particularidadeId: 57, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Bebe em Branco Inferior Esquerdo', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/bebe_em_branco_inferior_esquerdo', dimensao: null },
            { espelhoId: 18, particularidadeId: 58, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Bebe em Branco Superior Esquerdo', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/bebe_em_branco_superior_esquerdo', dimensao: null },
            { espelhoId: 19, particularidadeId: 5, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Ladre ou Beta Esquerdo', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/ladre_ou_beta_esquerdo', dimensao: null },
            { espelhoId: 20, particularidadeId: 6, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Bocalvo Direito', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/bocalvo_esquerdo', dimensao: null },
            { espelhoId: 21, particularidadeId: 60, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Casco Rajado Anterior', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/casco_rajado_direito_anterior', dimensao: null },
            { espelhoId: 22, particularidadeId: 13, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Casco Rajado Posterior', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/casco_rajado_direito_posterior', dimensao: null },
            { espelhoId: 23, particularidadeId: 59, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Casco Branco Anterior', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/casco_branco_direito_anterior', dimensao: null },
            { espelhoId: 24, particularidadeId: 11, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Casco Branco Posterior', regiaoId: null, categoriaId: _this.ECategoria.TRONCO, src: 'tronco/v2/espelhamento/casco_branco_direito_posterior', dimensao: null },
            { espelhoId: 25, particularidadeId: 2, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Alto Calçado', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado', dimensao: { largura: 0, altura: 2} },
            { espelhoId: 26, particularidadeId: 3, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Baixo Calçado', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado', dimensao: { largura: 0, altura: 2} },
            { espelhoId: 27, particularidadeId: 39, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Médio Calçado', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado', dimensao: { largura: 0, altura: 2} },
            { espelhoId: 28, particularidadeId: 49, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Alto Calçado Incompleto', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado_incompleto', dimensao: { largura: 0, altura: 2} },
            { espelhoId: 29, particularidadeId: 50, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Baixo Calçado Incompleto', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado_incompleto', dimensao: { largura: 0, altura: 2} },
            { espelhoId: 30, particularidadeId: 51, desenhoId: _this.EDesenho.LATERAL_ESQUERDA, nome: 'Médio Calçado Incompleto', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado_incompleto', dimensao: { largura: 0, altura: 2} },

            // CABEÇA 
            { espelhoId: 31, particularidadeId: 4, desenhoId: _this.EDesenho.CABECA, nome: 'Bebe em Branco', regiaoId: null, categoriaId: _this.ECategoria.CABECA, src: 'cabeca/v2/espelhamento/bebe_em_branco', dimensao: null },
            { espelhoId: 32, particularidadeId: 57, desenhoId: _this.EDesenho.CABECA, nome: 'Bebe em Branco Inferior', regiaoId: null, categoriaId: _this.ECategoria.CABECA, src: 'cabeca/v2/espelhamento/bebe_em_branco_inferior', dimensao: null },
            { espelhoId: 33, particularidadeId: 58, desenhoId: _this.EDesenho.CABECA, nome: 'Bebe em Branco Superior', regiaoId: null, categoriaId: _this.ECategoria.CABECA, src: 'cabeca/v2/espelhamento/bebe_em_branco_superior', dimensao: null },
            { espelhoId: 34, particularidadeId: 5, desenhoId: _this.EDesenho.CABECA, nome: 'Ladre ou Beta', regiaoId: null, categoriaId: _this.ECategoria.CABECA, src: 'cabeca/v2/espelhamento/ladre_ou_beta', dimensao: null },
            { espelhoId: 35, particularidadeId: 6, desenhoId: _this.EDesenho.CABECA, nome: 'Bocalvo', regiaoId: null, categoriaId: _this.ECategoria.CABECA, src: 'cabeca/v2/espelhamento/bocalvo', dimensao: null },

            // MEMBRO ANTERIOR 
            { espelhoId: 36, particularidadeId: 60, desenhoId: _this.EDesenho.MEMBROS_ANTERIORES, nome: 'Casco Rajado Anterior', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/v2/espelhamento/casco_rajado_{lado}_anterior', dimensao: { largura: 75, altura: 123.5} },
            { espelhoId: 37, particularidadeId: 59, desenhoId: _this.EDesenho.MEMBROS_ANTERIORES, nome: 'Casco Branco Anterior', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/v2/espelhamento/casco_branco_{lado}_anterior', dimensao: { largura: 75, altura: 123.5} },
            { espelhoId: 38, particularidadeId: 61, desenhoId: _this.EDesenho.MEMBROS_ANTERIORES, nome: 'Calçado Sobre a Coroa Anterior', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/v2/espelhamento/calcado_sobre_a_coroa_anterior_{lado}', dimensao: { largura: 75, altura: 123.5} },
            { espelhoId: 39, particularidadeId: 2, desenhoId: _this.EDesenho.MEMBROS_ANTERIORES, nome: 'Alto Calçado', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado', dimensao: { largura: 0, altura: 1.5} },
            { espelhoId: 40, particularidadeId: 3, desenhoId: _this.EDesenho.MEMBROS_ANTERIORES, nome: 'Baixo Calçado', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado', dimensao: { largura: 0, altura: 1.5} },
            { espelhoId: 41, particularidadeId: 39, desenhoId: _this.EDesenho.MEMBROS_ANTERIORES, nome: 'Médio Calçado', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado', dimensao: { largura: 0, altura: 1.5} },
            { espelhoId: 42, particularidadeId: 49, desenhoId: _this.EDesenho.MEMBROS_ANTERIORES, nome: 'Alto Calçado Incompleto', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado_incompleto', dimensao: { largura: 0, altura: 1.5} },
            { espelhoId: 43, particularidadeId: 50, desenhoId: _this.EDesenho.MEMBROS_ANTERIORES, nome: 'Baixo Calçado Incompleto', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado_incompleto', dimensao: { largura: 0, altura: 1.5} },
            { espelhoId: 44, particularidadeId: 51, desenhoId: _this.EDesenho.MEMBROS_ANTERIORES, nome: 'Médio Calçado Incompleto', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado_incompleto', dimensao: { largura: 0, altura: 1.5} },

            // MEMBRO POSTERIOR 
            { espelhoId: 45, particularidadeId: 13, desenhoId: _this.EDesenho.MEMBROS_POSTERIORES, nome: 'Casco Rajado Posterior', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/v2/espelhamento/casco_rajado_{lado}_posterior', dimensao: { largura: 75, altura: 123.5} },
            { espelhoId: 46, particularidadeId: 11, desenhoId: _this.EDesenho.MEMBROS_POSTERIORES, nome: 'Casco Branco Posterior', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/v2/espelhamento/casco_branco_{lado}_posterior', dimensao: { largura: 75, altura: 123.5} },
            { espelhoId: 47, particularidadeId: 8, desenhoId: _this.EDesenho.MEMBROS_POSTERIORES, nome: 'Calçado Sobre a Coroa Posterior', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/v2/espelhamento/calcado_sobre_a_coroa_posterior_{lado}', dimensao: { largura: 75, altura: 123.5} },
            { espelhoId: 48, particularidadeId: 2, desenhoId: _this.EDesenho.MEMBROS_POSTERIORES, nome: 'Alto Calçado', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado', dimensao: { largura: 0, altura: 1.5} },
            { espelhoId: 49, particularidadeId: 3, desenhoId: _this.EDesenho.MEMBROS_POSTERIORES, nome: 'Baixo Calçado', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado', dimensao: { largura: 0, altura: 1.5} },
            { espelhoId: 50, particularidadeId: 39, desenhoId: _this.EDesenho.MEMBROS_POSTERIORES, nome: 'Médio Calçado', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado', dimensao: { largura: 0, altura: 1.5} },
            { espelhoId: 51, particularidadeId: 49, desenhoId: _this.EDesenho.MEMBROS_POSTERIORES, nome: 'Alto Calçado Incompleto', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado_incompleto', dimensao: { largura: 0, altura: 1.5} },
            { espelhoId: 52, particularidadeId: 50, desenhoId: _this.EDesenho.MEMBROS_POSTERIORES, nome: 'Baixo Calçado Incompleto', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado_incompleto', dimensao: { largura: 0, altura: 1.5} },
            { espelhoId: 53, particularidadeId: 51, desenhoId: _this.EDesenho.MEMBROS_POSTERIORES, nome: 'Médio Calçado Incompleto', regiaoId: null, categoriaId: _this.ECategoria.MEMBRO, src: 'membros/calcado_incompleto', dimensao: { largura: 0, altura: 1.5} }
        ];

        _this.regioes = [
            // cabeca
            { regiaoId: 1, desenhoId: 3, nomeSimplificado: null, nome: 'Orelha Esquerda', polygono: JSON.parse('[{"x":126,"y":8},{"x":123,"y":13},{"x":117,"y":40},{"x":126,"y":51},{"x":132,"y":56},{"x":138,"y":55},{"x":143,"y":51},{"x":148,"y":42},{"x":142,"y":26},{"x":133,"y":14}]'), espelho: false },
            { regiaoId: 2, desenhoId: 3, nomeSimplificado: null, nome: 'Orelha Direita', polygono: JSON.parse('[{"x":64,"y":15},{"x":54,"y":33},{"x":55,"y":47},{"x":57,"y":58},{"x":65,"y":59},{"x":71,"y":55},{"x":77,"y":49},{"x":81,"y":40},{"x":73,"y":26},{"x":72,"y":19},{"x":70,"y":15}]'), espelho: false },
            { regiaoId: 3, desenhoId: 3, nomeSimplificado: null, nome: 'Fronte - Acima da linha dos Olhos na Lateral Direita', polygono: JSON.parse('[{"x":54,"y":62},{"x":58,"y":68},{"x":56,"y":79},{"x":43,"y":103},{"x":41,"y":106},{"x":97,"y":107},{"x":97,"y":38},{"x":86,"y":39},{"x":78,"y":50},{"x":70,"y":58},{"x":57,"y":60}]'), espelho: false },
            { regiaoId: 4, desenhoId: 3, nomeSimplificado: null, nome: 'Fronte - Acima da linha dos Olhos na Lateral Esquerda', polygono: JSON.parse('[{"x":98,"y":38},{"x":98,"y":106},{"x":158,"y":105},{"x":159,"y":99},{"x":148,"y":84},{"x":145,"y":75},{"x":143,"y":62},{"x":138,"y":56},{"x":133,"y":58},{"x":124,"y":50},{"x":116,"y":40}]'), espelho: false },
            { regiaoId: 5, desenhoId: 3, nomeSimplificado: null, nome: 'Linha dos Olhos na Lateral Direita', polygono: JSON.parse('[{"x":40,"y":106},{"x":43,"y":125},{"x":46,"y":132},{"x":97,"y":132},{"x":96,"y":109}]'), espelho: false },
            { regiaoId: 6, desenhoId: 3, nomeSimplificado: null, nome: 'Linha dos Olhos na Lateral Esquerda', polygono: JSON.parse('[{"x":98,"y":108},{"x":99,"y":131},{"x":157,"y":130},{"x":158,"y":107}]'), espelho: false },
            { regiaoId: 7, desenhoId: 3, nomeSimplificado: null, nome: 'Fronte - Abaixo da linha dos Olhos na Lateral Direita', polygono: JSON.parse('[{"x":47,"y":133},{"x":49,"y":145},{"x":48,"y":151},{"x":97,"y":154},{"x":96,"y":133}]'), espelho: false },
            { regiaoId: 8, desenhoId: 3, nomeSimplificado: null, nome: 'Fronte - Abaixo da linha dos Olhos na Lateral Esquerda', polygono: JSON.parse('[{"x":98,"y":132},{"x":98,"y":152},{"x":155,"y":151},{"x":154,"y":131}]'), espelho: false },
            { regiaoId: 9, desenhoId: 3, nomeSimplificado: null, nome: 'Chanfro na Lateral Direita', polygono: JSON.parse('[{"x":49,"y":152},{"x":48,"y":164},{"x":49,"y":184},{"x":54,"y":194},{"x":63,"y":201},{"x":72,"y":194},{"x":75,"y":184},{"x":82,"y":179},{"x":93,"y":177},{"x":97,"y":176},{"x":97,"y":154}]'), espelho: false },
            { regiaoId: 10, desenhoId: 3, nomeSimplificado: null, nome: 'Chanfro na Lateral Esquerda', polygono: JSON.parse('[{"x":99,"y":154},{"x":99,"y":175},{"x":105,"y":175},{"x":113,"y":176},{"x":121,"y":178},{"x":127,"y":183},{"x":133,"y":194},{"x":138,"y":199},{"x":148,"y":184},{"x":153,"y":171},{"x":154,"y":151}]'), espelho: false },
            { regiaoId: 11, desenhoId: 3, nomeSimplificado: null, nome: 'Focinho na Lateral Direita', polygono: JSON.parse('[{"x":98,"y":176},{"x":83,"y":180},{"x":76,"y":185},{"x":73,"y":196},{"x":63,"y":202},{"x":65,"y":221},{"x":71,"y":234},{"x":80,"y":245},{"x":85,"y":255},{"x":97,"y":257}]'), espelho: false },
            { regiaoId: 12, desenhoId: 3, nomeSimplificado: null, nome: 'Focinho na Lateral Esquerda', polygono: JSON.parse('[{"x":100,"y":177},{"x":98,"y":256},{"x":110,"y":257},{"x":117,"y":252},{"x":120,"y":246},{"x":128,"y":236},{"x":137,"y":224},{"x":138,"y":213},{"x":138,"y":205},{"x":138,"y":199},{"x":129,"y":190},{"x":118,"y":178}]'), espelho: false },
            { regiaoId: 122, desenhoId: 3, nomeSimplificado: null, nome: 'Fronte', polygono: JSON.parse('[]'), espelho: false },
            { regiaoId: 125, desenhoId: 3, nomeSimplificado: null, nome: 'Chanfro', polygono: JSON.parse('[]'), espelho: false },
            { regiaoId: 204, desenhoId: 3, nomeSimplificado: null, nome: 'Focinho', polygono: JSON.parse('[]'), espelho: false },

            // lateral esquerda
            { regiaoId: 13, desenhoId: 2, nomeSimplificado: 'Membro Posterior', nome: 'Casco Traseiro', polygono: JSON.parse('[{"x":363,"y":319},{"x":378,"y":326},{"x":378,"y":331},{"x":361,"y":332}]'), espelho: false },
            { regiaoId: 14, desenhoId: 2, calcamentoId: 3, calcamentoIncompletoId: 50, zebruraId: 48, nomeSimplificado: 'Membro Posterior', nome: 'Quartela Traseira', polygono: JSON.parse('[{"x":369,"y":306},{"x":380,"y":310},{"x":378,"y":322},{"x":365,"y":316}]'), espelho: false },
            { regiaoId: 15, desenhoId: 2, calcamentoId: 39, calcamentoIncompletoId: 51, zebruraId: 48, nomeSimplificado: 'Membro Posterior', nome: 'Boleto Traseiro', polygono: JSON.parse('[{"x":371,"y":292},{"x":386,"y":289},{"x":385,"y":297},{"x":390,"y":304},{"x":382,"y":308},{"x":369,"y":303}]'), espelho: false },
            { regiaoId: 16, desenhoId: 2, calcamentoId: 39, calcamentoIncompletoId: 51, zebruraId: 48, nomeSimplificado: 'Membro Posterior', nome: 'Canela Traseira', polygono: JSON.parse('[{"x":370,"y":268},{"x":382,"y":265},{"x":385,"y":286},{"x":372,"y":289}]'), espelho: false },
            { regiaoId: 17, desenhoId: 2, calcamentoId: 2, calcamentoIncompletoId: 49, zebruraId: 48, nomeSimplificado: 'Membro Posterior', nome: 'Jarrete', polygono: JSON.parse('[{"x":378,"y":233},{"x":362,"y":246},{"x":367,"y":258},{"x":370,"y":263},{"x":370,"y":268},{"x":382,"y":265},{"x":382,"y":253},{"x":384,"y":244}]'), espelho: false },
            { regiaoId: 18, desenhoId: 2, calcamentoId: 2, calcamentoIncompletoId: 49, zebruraId: 48, nomeSimplificado: 'Membro Posterior', nome: 'Perna', polygono: JSON.parse('[{"x":334,"y":196},{"x":345,"y":198},{"x":352,"y":199},{"x":367,"y":199},{"x":371,"y":207},{"x":374,"y":214},{"x":377,"y":219},{"x":381,"y":226},{"x":361,"y":244},{"x":342,"y":206}]'), espelho: false },
            { regiaoId: 19, desenhoId: 2, nomeSimplificado: null, nome: 'Nádega', polygono: JSON.parse('[{"x":368,"y":126},{"x":374,"y":126},{"x":379,"y":134},{"x":380,"y":149},{"x":376,"y":164},{"x":371,"y":175},{"x":368,"y":188},{"x":368,"y":194},{"x":359,"y":196},{"x":363,"y":174},{"x":369,"y":165},{"x":373,"y":146}]'), espelho: false },
            { regiaoId: 20, desenhoId: 2, nomeSimplificado: null, nome: 'Coxa', polygono: JSON.parse('[{"x":306,"y":115},{"x":304,"y":115},{"x":304,"y":120},{"x":301,"y":123},{"x":299,"y":124},{"x":304,"y":153},{"x":308,"y":167},{"x":319,"y":172},{"x":328,"y":179},{"x":334,"y":193},{"x":334,"y":195},{"x":358,"y":198},{"x":362,"y":179},{"x":367,"y":165},{"x":370,"y":154},{"x":371,"y":145},{"x":370,"y":132},{"x":367,"y":125},{"x":343,"y":123}]'), espelho: false },
            { regiaoId: 21, desenhoId: 2, nomeSimplificado: null, nome: 'Garupa', polygono: JSON.parse('[{"x":299,"y":100},{"x":317,"y":97},{"x":334,"y":98},{"x":348,"y":101},{"x":359,"y":107},{"x":368,"y":112},{"x":371,"y":120},{"x":374,"y":124},{"x":374,"y":123},{"x":368,"y":124},{"x":354,"y":123},{"x":330,"y":119},{"x":315,"y":116},{"x":305,"y":114},{"x":303,"y":112},{"x":299,"y":108}]'), espelho: false },
            { regiaoId: 22, desenhoId: 2, nomeSimplificado: null, nome: 'Lombo', polygono: JSON.parse('[{"x":261,"y":104},{"x":275,"y":102},{"x":281,"y":102},{"x":296,"y":100},{"x":297,"y":106},{"x":298,"y":108},{"x":292,"y":111},{"x":290,"y":114},{"x":290,"y":116},{"x":282,"y":119},{"x":275,"y":119},{"x":270,"y":120}]'), espelho: false },
            { regiaoId: 23, desenhoId: 2, nomeSimplificado: null, nome: 'Anca', polygono: JSON.parse('[{"x":291,"y":112},{"x":297,"y":109},{"x":303,"y":112},{"x":304,"y":118},{"x":300,"y":122},{"x":295,"y":122},{"x":290,"y":121}]'), espelho: false },
            { regiaoId: 24, desenhoId: 2, nomeSimplificado: null, nome: 'Vazio do Flanco', polygono: JSON.parse('[{"x":278,"y":138},{"x":275,"y":133},{"x":284,"y":131},{"x":290,"y":131},{"x":293,"y":135},{"x":294,"y":140},{"x":291,"y":144},{"x":287,"y":147},{"x":280,"y":144},{"x":276,"y":144},{"x":276,"y":139}]'), espelho: false },
            { regiaoId: 25, desenhoId: 2, nomeSimplificado: null, nome: 'Flanco', polygono: JSON.parse('[{"x":271,"y":121},{"x":276,"y":137},{"x":283,"y":131},{"x":292,"y":131},{"x":295,"y":137},{"x":295,"y":142},{"x":293,"y":145},{"x":288,"y":147},{"x":282,"y":146},{"x":277,"y":143},{"x":276,"y":154},{"x":273,"y":162},{"x":269,"y":167},{"x":297,"y":176},{"x":306,"y":166},{"x":303,"y":154},{"x":300,"y":138},{"x":299,"y":131},{"x":298,"y":126},{"x":299,"y":124},{"x":292,"y":122},{"x":290,"y":117}]'), espelho: false },
            { regiaoId: 26, desenhoId: 2, nomeSimplificado: null, nome: 'Cilhadouro', polygono: JSON.parse('[{"x":170,"y":183},{"x":172,"y":186},{"x":172,"y":191},{"x":182,"y":192},{"x":192,"y":192},{"x":201,"y":193},{"x":198,"y":185}]'), espelho: false },
            { regiaoId: 27, desenhoId: 2, nomeSimplificado: null, nome: 'Ventre', polygono: JSON.parse('[{"x":267,"y":168},{"x":255,"y":177},{"x":247,"y":182},{"x":235,"y":184},{"x":218,"y":186},{"x":200,"y":186},{"x":202,"y":191},{"x":202,"y":193},{"x":226,"y":196},{"x":246,"y":196},{"x":268,"y":195},{"x":284,"y":193},{"x":297,"y":192},{"x":296,"y":183},{"x":296,"y":181},{"x":297,"y":177}]'), espelho: false },
            { regiaoId: 28, desenhoId: 2, nomeSimplificado: null, nome: 'Costado', polygono: JSON.parse('[{"x":190,"y":121},{"x":202,"y":123},{"x":210,"y":123},{"x":218,"y":124},{"x":234,"y":125},{"x":245,"y":124},{"x":258,"y":122},{"x":268,"y":122},{"x":273,"y":129},{"x":275,"y":142},{"x":276,"y":154},{"x":272,"y":164},{"x":266,"y":170},{"x":256,"y":175},{"x":242,"y":180},{"x":229,"y":185},{"x":211,"y":186},{"x":198,"y":184},{"x":188,"y":185},{"x":179,"y":183},{"x":171,"y":183},{"x":168,"y":181},{"x":176,"y":170},{"x":182,"y":157},{"x":187,"y":142}]'), espelho: false },
            { regiaoId: 29, desenhoId: 2, nomeSimplificado: null, nome: 'Dorso', polygono: JSON.parse('[{"x":188,"y":108},{"x":201,"y":107},{"x":210,"y":105},{"x":213,"y":100},{"x":221,"y":103},{"x":237,"y":106},{"x":250,"y":105},{"x":260,"y":105},{"x":269,"y":121},{"x":250,"y":123},{"x":227,"y":123},{"x":212,"y":123},{"x":198,"y":122},{"x":189,"y":119}]'), espelho: false },
            { regiaoId: 30, desenhoId: 2, nomeSimplificado: null, nome: 'Cernelha', polygono: JSON.parse('[{"x":190,"y":88},{"x":179,"y":94},{"x":185,"y":102},{"x":189,"y":109},{"x":203,"y":106},{"x":211,"y":101}]'), espelho: false },
            { regiaoId: 31, desenhoId: 2, nomeSimplificado: null, nome: 'Espádua', polygono: JSON.parse('[{"x":178,"y":96},{"x":113,"y":138},{"x":119,"y":137},{"x":123,"y":139},{"x":132,"y":142},{"x":141,"y":147},{"x":150,"y":153},{"x":157,"y":162},{"x":163,"y":172},{"x":166,"y":179},{"x":170,"y":175},{"x":177,"y":163},{"x":183,"y":151},{"x":187,"y":138},{"x":188,"y":122},{"x":188,"y":109}]'), espelho: false },
            { regiaoId: 32, desenhoId: 2, nomeSimplificado: null, nome: 'Braço', polygono: JSON.parse('[{"x":112,"y":139},{"x":113,"y":148},{"x":122,"y":160},{"x":125,"y":173},{"x":128,"y":181},{"x":128,"y":190},{"x":137,"y":193},{"x":147,"y":190},{"x":156,"y":189},{"x":158,"y":183},{"x":162,"y":180},{"x":167,"y":181},{"x":160,"y":169},{"x":153,"y":158},{"x":144,"y":148},{"x":128,"y":140}]'), espelho: false },
            { regiaoId: 33, desenhoId: 2, nomeSimplificado: null, nome: 'Codilho', polygono: JSON.parse('[{"x":163,"y":181},{"x":157,"y":185},{"x":158,"y":192},{"x":161,"y":196},{"x":167,"y":196},{"x":172,"y":192},{"x":170,"y":185}]'), espelho: false },
            { regiaoId: 34, desenhoId: 2, nomeSimplificado: null, nome: 'Peito', polygono: JSON.parse('[{"x":113,"y":149},{"x":115,"y":170},{"x":119,"y":183},{"x":127,"y":189},{"x":124,"y":170},{"x":120,"y":158}]'), espelho: false },
            { regiaoId: 35, desenhoId: 2, calcamentoId: 2, calcamentoIncompletoId: 49, zebruraId: 48, nomeSimplificado: 'Membro Anterior', nome: 'Ante-Braço', polygono: JSON.parse('[{"x":130,"y":193},{"x":131,"y":198},{"x":137,"y":204},{"x":140,"y":215},{"x":147,"y":231},{"x":148,"y":233},{"x":159,"y":233},{"x":167,"y":230},{"x":166,"y":216},{"x":166,"y":211},{"x":170,"y":204},{"x":170,"y":195},{"x":164,"y":198},{"x":156,"y":193},{"x":155,"y":190}]'), espelho: false },
            { regiaoId: 36, desenhoId: 2, calcamentoId: 2, calcamentoIncompletoId: 49, zebruraId: 48, nomeSimplificado: 'Membro Anterior', nome: 'Joelho', polygono: JSON.parse('[{"x":147,"y":233},{"x":153,"y":244},{"x":155,"y":254},{"x":169,"y":252},{"x":167,"y":232}]'), espelho: false },
            { regiaoId: 37, desenhoId: 2, calcamentoId: 39, calcamentoIncompletoId: 51, zebruraId: 48, nomeSimplificado: 'Membro Anterior', nome: 'Canela Dianteira', polygono: JSON.parse('[{"x":156,"y":255},{"x":163,"y":292},{"x":164,"y":294},{"x":175,"y":291},{"x":169,"y":253}]'), espelho: false },
            { regiaoId: 38, desenhoId: 2, calcamentoId: 39, calcamentoIncompletoId: 51, zebruraId: 48, nomeSimplificado: 'Membro Anterior', nome: 'Boleto Dianteiro', polygono: JSON.parse('[{"x":164,"y":295},{"x":164,"y":303},{"x":174,"y":305},{"x":178,"y":302},{"x":175,"y":294}]'), espelho: false },
            { regiaoId: 39, desenhoId: 2, nomeSimplificado: null, nome: 'Goteira da Jugular', polygono: JSON.parse('[{"x":84,"y":59},{"x":84,"y":65},{"x":90,"y":73},{"x":94,"y":79},{"x":99,"y":89},{"x":103,"y":97},{"x":106,"y":108},{"x":108,"y":117},{"x":110,"y":104},{"x":105,"y":91},{"x":99,"y":78},{"x":91,"y":67}]'), espelho: false },
            { regiaoId: 40, desenhoId: 2, nomeSimplificado: null, nome: 'Pescoço no Terço Cranial - Bordo Inferior', polygono: JSON.parse('[{"x":71,"y":74},{"x":84,"y":67},{"x":95,"y":81},{"x":90,"y":86}]'), espelho: false },
            { regiaoId: 41, desenhoId: 2, nomeSimplificado: null, nome: 'Pescoço no Terço Médio - Bordo Inferior', polygono: JSON.parse('[{"x":95,"y":82},{"x":102,"y":96},{"x":103,"y":102},{"x":90,"y":87}]'), espelho: false },
            { regiaoId: 43, desenhoId: 2, nomeSimplificado: null, nome: 'Pescoço no Terço Cranial - Tábua do Pescoço', polygono: JSON.parse('[{"x":81,"y":38},{"x":88,"y":38},{"x":89,"y":41},{"x":101,"y":45},{"x":120,"y":53},{"x":107,"y":67},{"x":98,"y":77},{"x":91,"y":67},{"x":83,"y":60}]'), espelho: false },
            { regiaoId: 44, desenhoId: 2, nomeSimplificado: null, nome: 'Pescoço no Terço Médio - Tábua do Pescoço', polygono: JSON.parse('[{"x":122,"y":54},{"x":138,"y":64},{"x":155,"y":76},{"x":134,"y":89},{"x":113,"y":105},{"x":109,"y":102},{"x":100,"y":79}]'), espelho: false },
            { regiaoId: 45, desenhoId: 2, nomeSimplificado: null, nome: 'Pescoço no Terço Caudal - Tábua do Pescoço', polygono: JSON.parse('[{"x":156,"y":77},{"x":171,"y":86},{"x":180,"y":91},{"x":167,"y":100},{"x":157,"y":109},{"x":130,"y":124},{"x":114,"y":136},{"x":110,"y":127},{"x":109,"y":118},{"x":111,"y":109}]'), espelho: false },
            { regiaoId: 46, desenhoId: 2, nomeSimplificado: null, nome: 'Pescoço no Terço Cranial - Bordo Superior', polygono: JSON.parse('[{"x":90,"y":32},{"x":109,"y":36},{"x":123,"y":42},{"x":126,"y":46},{"x":122,"y":53},{"x":104,"y":44},{"x":90,"y":40}]'), espelho: false },
            { regiaoId: 47, desenhoId: 2, nomeSimplificado: null, nome: 'Pescoço no Terço Caudal - Bordo Superior', polygono: JSON.parse('[{"x":164,"y":69},{"x":174,"y":80},{"x":189,"y":87},{"x":182,"y":91},{"x":168,"y":82},{"x":158,"y":77}]'), espelho: false },
            { regiaoId: 48, desenhoId: 2, nomeSimplificado: null, nome: 'Pescoço no Terço Médio - Bordo Superior', polygono: JSON.parse('[{"x":128,"y":47},{"x":149,"y":61},{"x":160,"y":68},{"x":163,"y":69},{"x":157,"y":76},{"x":139,"y":63},{"x":124,"y":54}]'), espelho: false },
            { regiaoId: 49, desenhoId: 2, nomeSimplificado: null, nome: 'Nuca', polygono: JSON.parse('[{"x":78,"y":28},{"x":79,"y":37},{"x":90,"y":38},{"x":89,"y":32}]'), espelho: false },
            { regiaoId: 50, desenhoId: 2, nomeSimplificado: null, nome: 'Face', polygono: JSON.parse('[{"x":73,"y":28},{"x":73,"y":45},{"x":65,"y":57},{"x":55,"y":65},{"x":45,"y":70},{"x":51,"y":79},{"x":55,"y":73},{"x":61,"y":75},{"x":69,"y":75},{"x":83,"y":65},{"x":82,"y":38},{"x":77,"y":38},{"x":78,"y":28}]'), espelho: false },
            { regiaoId: 51, desenhoId: 2, nomeSimplificado: null, nome: 'Bochecha', polygono: JSON.parse('[{"x":17,"y":75},{"x":24,"y":78},{"x":27,"y":85},{"x":37,"y":80},{"x":52,"y":79},{"x":43,"y":70}]'), espelho: false },
            { regiaoId: 52, desenhoId: 2, nomeSimplificado: null, nome: 'Fronte', polygono: JSON.parse('[{"x":64,"y":28},{"x":38,"y":48},{"x":52,"y":68},{"x":65,"y":56},{"x":73,"y":39},{"x":72,"y":27}]'), espelho: false },
            { regiaoId: 53, desenhoId: 2, nomeSimplificado: null, nome: 'Chanfro', polygono: JSON.parse('[{"x":38,"y":49},{"x":11,"y":67},{"x":15,"y":71},{"x":16,"y":74},{"x":35,"y":73},{"x":51,"y":66}]'), espelho: false },
            { regiaoId: 54, desenhoId: 2, nomeSimplificado: null, nome: 'Focinho', polygono: JSON.parse('[{"x":7,"y":73},{"x":11,"y":84},{"x":21,"y":80},{"x":21,"y":87},{"x":26,"y":88},{"x":25,"y":82}]'), espelho: false },
            { regiaoId: 55, desenhoId: 2, nomeSimplificado: null, nome: 'Úbere', polygono: JSON.parse('[{"x":298,"y":179},{"x":298,"y":191},{"x":315,"y":187}]'), espelho: false },
            { regiaoId: 56, desenhoId: 2, nomeSimplificado: null, nome: 'Virilha', polygono: JSON.parse('[{"x":307,"y":168},{"x":298,"y":178},{"x":315,"y":184}]'), espelho: false },
            { regiaoId: 57, desenhoId: 2, nomeSimplificado: null, nome: 'Soldra', polygono: JSON.parse('[{"x":309,"y":168},{"x":316,"y":183},{"x":327,"y":190},{"x":332,"y":193},{"x":328,"y":180},{"x":319,"y":173}]'), espelho: false },
            { regiaoId: 58, desenhoId: 2, nomeSimplificado: null, nome: 'Curvilhão', polygono: JSON.parse('[{"x":382,"y":228},{"x":379,"y":235},{"x":381,"y":238},{"x":383,"y":242},{"x":385,"y":243},{"x":385,"y":234}]'), espelho: false },
            { regiaoId: 59, desenhoId: 2, calcamentoId: 3, calcamentoIncompletoId: 50, zebruraId: 48, nomeSimplificado: 'Membro Anterior', nome: 'Quartela Dianteira', polygono: JSON.parse('[{"x":163,"y":304},{"x":160,"y":313},{"x":175,"y":319},{"x":174,"y":307}]'), espelho: false },
            { regiaoId: 60, desenhoId: 2, nomeSimplificado: 'Membro Anterior', nome: 'Casco Dianteiro', polygono: JSON.parse('[{"x":160,"y":315},{"x":152,"y":323},{"x":153,"y":329},{"x":178,"y":326},{"x":175,"y":320}]'), espelho: false },
            { regiaoId: 61, desenhoId: 2, nomeSimplificado: null, nome: 'Ganacha', polygono: JSON.parse('[{"x":55,"y":73},{"x":52,"y":76},{"x":52,"y":80},{"x":51,"y":80},{"x":62,"y":78}]'), espelho: false },
            { regiaoId: 123, desenhoId: 2, nomeSimplificado: null, nome: 'Tronco', polygono: JSON.parse('[]'), espelho: false },
            { regiaoId: 127, desenhoId: 2, nomeSimplificado: null, nome: 'Costas', polygono: JSON.parse('[]'), espelho: false },

            { regiaoId: 200, desenhoId: 2, nomeSimplificado: null, nome: 'Espelho Membro Anterior Direito', polygono: JSON.parse('[{"x":130,"y":199},{"x":129,"y":222},{"x":132,"y":236},{"x":127,"y":247},{"x":128,"y":267},{"x":130,"y":290},{"x":128,"y":297},{"x":124,"y":304},{"x":121,"y":309},{"x":116,"y":312},{"x":131,"y":318},{"x":135,"y":314},{"x":134,"y":309},{"x":139,"y":307},{"x":143,"y":303},{"x":144,"y":289},{"x":145,"y":270},{"x":146,"y":255},{"x":150,"y":243},{"x":145,"y":228},{"x":137,"y":211}]'), espelho: true },
            { regiaoId: 201, desenhoId: 2, nomeSimplificado: null, nome: 'Espelho Membro Posterior Direito', polygono: JSON.parse('[{"x":319,"y":186},{"x":315,"y":191},{"x":318,"y":196},{"x":321,"y":211},{"x":326,"y":231},{"x":326,"y":237},{"x":327,"y":245},{"x":330,"y":261},{"x":330,"y":278},{"x":324,"y":298},{"x":319,"y":314},{"x":314,"y":320},{"x":328,"y":326},{"x":335,"y":313},{"x":335,"y":300},{"x":339,"y":284},{"x":343,"y":270},{"x":349,"y":254},{"x":352,"y":244},{"x":355,"y":236},{"x":348,"y":223},{"x":345,"y":213},{"x":343,"y":209},{"x":338,"y":205},{"x":331,"y":198},{"x":331,"y":194}]'), espelho: true },
            { regiaoId: 205, desenhoId: 2, nomeSimplificado: null, nome: 'Espelho Casco Anterior', polygono: JSON.parse('[]'), espelho: true },
            { regiaoId: 206, desenhoId: 2, nomeSimplificado: null, nome: 'Espelho Casco Posterior', polygono: JSON.parse('[]'), espelho: true },

            // lateral direita
            { regiaoId: 62, desenhoId: 1, nomeSimplificado: null, nome: 'Focinho', polygono: JSON.parse('[{"x":394,"y":73},{"x":387,"y":75},{"x":382,"y":78},{"x":376,"y":86},{"x":382,"y":86},{"x":382,"y":81},{"x":390,"y":84},{"x":395,"y":78}]'), espelho: false },
            { regiaoId: 63, desenhoId: 1, nomeSimplificado: null, nome: 'Bochecha', polygono: JSON.parse('[{"x":359,"y":71},{"x":353,"y":79},{"x":359,"y":82},{"x":364,"y":80},{"x":374,"y":86},{"x":379,"y":78},{"x":384,"y":74}]'), espelho: false },
            { regiaoId: 64, desenhoId: 1, nomeSimplificado: null, nome: 'Ganacha', polygono: JSON.parse('[{"x":348,"y":73},{"x":341,"y":77},{"x":351,"y":80}]'), espelho: false },
            { regiaoId: 65, desenhoId: 1, nomeSimplificado: null, nome: 'Nuca', polygono: JSON.parse('[{"x":325,"y":28},{"x":326,"y":36},{"x":315,"y":36},{"x":316,"y":30}]'), espelho: false },
            { regiaoId: 66, desenhoId: 1, nomeSimplificado: null, nome: 'Face', polygono: JSON.parse('[{"x":326,"y":29},{"x":327,"y":36},{"x":321,"y":36},{"x":320,"y":48},{"x":319,"y":60},{"x":322,"y":69},{"x":332,"y":75},{"x":342,"y":76},{"x":348,"y":72},{"x":353,"y":78},{"x":357,"y":70},{"x":343,"y":62},{"x":336,"y":56}]'), espelho: false },
            { regiaoId: 67, desenhoId: 1, nomeSimplificado: null, nome: 'Chanfro', polygono: JSON.parse('[{"x":364,"y":47},{"x":352,"y":62},{"x":357,"y":69},{"x":387,"y":73},{"x":390,"y":64},{"x":373,"y":52}]'), espelho: false },
            { regiaoId: 68, desenhoId: 1, nomeSimplificado: null, nome: 'Fronte', polygono: JSON.parse('[{"x":339,"y":26},{"x":327,"y":29},{"x":336,"y":52},{"x":343,"y":61},{"x":348,"y":64},{"x":363,"y":46}]'), espelho: false },
            { regiaoId: 69, desenhoId: 1, nomeSimplificado: null, nome: 'Goteira da Jugular', polygono: JSON.parse('[{"x":318,"y":60},{"x":305,"y":77},{"x":299,"y":90},{"x":293,"y":104},{"x":293,"y":112},{"x":293,"y":116},{"x":299,"y":105},{"x":303,"y":93},{"x":309,"y":81},{"x":319,"y":65}]'), espelho: false },
            { regiaoId: 70, desenhoId: 1, nomeSimplificado: null, nome: 'Pescoço no Terço Cranial - Bordo Superior', polygono: JSON.parse('[{"x":315,"y":31},{"x":315,"y":35},{"x":304,"y":40},{"x":295,"y":45},{"x":287,"y":49},{"x":281,"y":52},{"x":276,"y":45},{"x":292,"y":37}]'), espelho: false },
            { regiaoId: 71, desenhoId: 1, nomeSimplificado: null, nome: 'Pescoço no Terço Cranial - Tábua do Pescoço', polygono: JSON.parse('[{"x":320,"y":36},{"x":314,"y":36},{"x":282,"y":53},{"x":296,"y":67},{"x":305,"y":77},{"x":317,"y":60}]'), espelho: false },
            { regiaoId: 72, desenhoId: 1, nomeSimplificado: null, nome: 'Pescoço no Terço Cranial - Bordo Inferior', polygono: JSON.parse('[{"x":319,"y":66},{"x":310,"y":82},{"x":313,"y":86},{"x":325,"y":80},{"x":332,"y":77}]'), espelho: false },
            { regiaoId: 73, desenhoId: 1, nomeSimplificado: null, nome: 'Pescoço no Terço Médio - Bordo Superior', polygono: JSON.parse('[{"x":275,"y":46},{"x":280,"y":53},{"x":261,"y":66},{"x":250,"y":72},{"x":246,"y":75},{"x":239,"y":71},{"x":255,"y":62}]'), espelho: false },
            { regiaoId: 74, desenhoId: 1, nomeSimplificado: null, nome: 'Pescoço no Terço Médio - Tábua do Pescoço', polygono: JSON.parse('[{"x":280,"y":54},{"x":261,"y":67},{"x":247,"y":75},{"x":293,"y":106},{"x":303,"y":79}]'), espelho: false },
            { regiaoId: 75, desenhoId: 1, nomeSimplificado: null, nome: 'Pescoço no Terço Médio - Bordo Inferior', polygono: JSON.parse('[{"x":309,"y":82},{"x":305,"y":92},{"x":303,"y":100},{"x":314,"y":88}]'), espelho: false },
            { regiaoId: 76, desenhoId: 1, nomeSimplificado: null, nome: 'Pescoço no Terço Caudal - Bordo Superior', polygono: JSON.parse('[{"x":238,"y":70},{"x":246,"y":76},{"x":222,"y":93},{"x":215,"y":85}]'), espelho: false },
            { regiaoId: 77, desenhoId: 1, nomeSimplificado: null, nome: 'Pescoço no Terço Caudal - Tábua do Pescoço', polygono: JSON.parse('[{"x":246,"y":76},{"x":224,"y":93},{"x":290,"y":134},{"x":293,"y":117},{"x":292,"y":109}]'), espelho: false },
            { regiaoId: 79, desenhoId: 1, nomeSimplificado: null, nome: 'Cernelha', polygono: JSON.parse('[{"x":213,"y":86},{"x":191,"y":100},{"x":196,"y":107},{"x":214,"y":109},{"x":216,"y":100},{"x":224,"y":95}]'), espelho: false },
            { regiaoId: 80, desenhoId: 1, nomeSimplificado: null, nome: 'Espádua', polygono: JSON.parse('[{"x":225,"y":95},{"x":216,"y":106},{"x":215,"y":125},{"x":219,"y":147},{"x":230,"y":170},{"x":235,"y":182},{"x":237,"y":180},{"x":245,"y":166},{"x":256,"y":151},{"x":270,"y":143},{"x":281,"y":139},{"x":290,"y":139},{"x":290,"y":135}]'), espelho: false },
            { regiaoId: 81, desenhoId: 1, nomeSimplificado: null, nome: 'Braço', polygono: JSON.parse('[{"x":289,"y":138},{"x":276,"y":141},{"x":265,"y":147},{"x":256,"y":151},{"x":248,"y":164},{"x":240,"y":177},{"x":237,"y":180},{"x":244,"y":183},{"x":248,"y":188},{"x":248,"y":190},{"x":256,"y":191},{"x":265,"y":191},{"x":275,"y":191},{"x":278,"y":168},{"x":284,"y":152},{"x":290,"y":148}]'), espelho: false },
            { regiaoId: 82, desenhoId: 1, nomeSimplificado: null, nome: 'Peito', polygono: JSON.parse('[{"x":289,"y":150},{"x":280,"y":162},{"x":278,"y":174},{"x":276,"y":188},{"x":286,"y":181},{"x":289,"y":164}]'), espelho: false },
            { regiaoId: 83, desenhoId: 1, nomeSimplificado: null, nome: 'Codilho', polygono: JSON.parse('[{"x":240,"y":181},{"x":233,"y":184},{"x":231,"y":193},{"x":235,"y":197},{"x":241,"y":197},{"x":245,"y":194},{"x":247,"y":190},{"x":244,"y":184}]'), espelho: false },
            { regiaoId: 84, desenhoId: 1, calcamentoId: 2, calcamentoIncompletoId: 49, zebruraId: 48, nomeSimplificado: 'Membro Anterior', nome: 'Ante-braço', polygono: JSON.parse('[{"x":249,"y":190},{"x":245,"y":196},{"x":242,"y":197},{"x":232,"y":196},{"x":237,"y":210},{"x":237,"y":223},{"x":236,"y":231},{"x":256,"y":232},{"x":263,"y":215},{"x":268,"y":203},{"x":274,"y":195},{"x":274,"y":191}]'), espelho: false },
            { regiaoId: 85, desenhoId: 1, calcamentoId: 2, calcamentoIncompletoId: 49, zebruraId: 48, nomeSimplificado: 'Membro Anterior', nome: 'Joelho', polygono: JSON.parse('[{"x":235,"y":231},{"x":233,"y":252},{"x":247,"y":255},{"x":249,"y":241},{"x":256,"y":232}]'), espelho: false },
            { regiaoId: 86, desenhoId: 1, calcamentoId: 39, calcamentoIncompletoId: 51, zebruraId: 48, nomeSimplificado: 'Membro Anterior', nome: 'Canela Dianteira', polygono: JSON.parse('[{"x":233,"y":253},{"x":227,"y":292},{"x":239,"y":293},{"x":248,"y":254}]'), espelho: false },
            { regiaoId: 87, desenhoId: 1, calcamentoId: 39, calcamentoIncompletoId: 51, zebruraId: 48, nomeSimplificado: 'Membro Anterior', nome: 'Boleto Dianteiro', polygono: JSON.parse('[{"x":226,"y":292},{"x":224,"y":301},{"x":228,"y":306},{"x":240,"y":303},{"x":240,"y":294}]'), espelho: false },
            { regiaoId: 88, desenhoId: 1, calcamentoId: 3, calcamentoIncompletoId: 50, zebruraId: 48, nomeSimplificado: 'Membro Anterior', nome: 'Quartela Dianteira', polygono: JSON.parse('[{"x":229,"y":307},{"x":228,"y":319},{"x":243,"y":313},{"x":240,"y":305}]'), espelho: false },
            { regiaoId: 89, desenhoId: 1, nomeSimplificado: 'Membro Anterior', nome: 'Casco Dianteiro', polygono: JSON.parse('[{"x":227,"y":320},{"x":227,"y":326},{"x":238,"y":328},{"x":249,"y":327},{"x":248,"y":323},{"x":243,"y":313}]'), espelho: false },
            { regiaoId: 90, desenhoId: 1, nomeSimplificado: null, nome: 'Dorso', polygono: JSON.parse('[{"x":190,"y":99},{"x":182,"y":103},{"x":142,"y":104},{"x":133,"y":121},{"x":153,"y":124},{"x":172,"y":123},{"x":189,"y":124},{"x":200,"y":121},{"x":216,"y":120},{"x":214,"y":109},{"x":195,"y":108}]'), espelho: false },
            { regiaoId: 91, desenhoId: 1, nomeSimplificado: null, nome: 'Costado', polygono: JSON.parse('[{"x":132,"y":121},{"x":127,"y":145},{"x":131,"y":164},{"x":146,"y":176},{"x":164,"y":185},{"x":183,"y":185},{"x":201,"y":186},{"x":215,"y":184},{"x":234,"y":182},{"x":229,"y":172},{"x":221,"y":155},{"x":216,"y":139},{"x":215,"y":128},{"x":215,"y":119},{"x":184,"y":126}]'), espelho: false },
            { regiaoId: 92, desenhoId: 1, nomeSimplificado: null, nome: 'Cilhadouro', polygono: JSON.parse('[{"x":232,"y":182},{"x":206,"y":185},{"x":205,"y":194},{"x":229,"y":192}]'), espelho: false },
            { regiaoId: 93, desenhoId: 1, nomeSimplificado: null, nome: 'Úbere', polygono: JSON.parse('[{"x":100,"y":181},{"x":84,"y":186},{"x":99,"y":192}]'), espelho: false },
            { regiaoId: 94, desenhoId: 1, nomeSimplificado: null, nome: 'Virilha', polygono: JSON.parse('[{"x":94,"y":171},{"x":86,"y":183},{"x":97,"y":179}]'), espelho: false },
            { regiaoId: 95, desenhoId: 1, nomeSimplificado: null, nome: 'Soldra', polygono: JSON.parse('[{"x":93,"y":169},{"x":83,"y":174},{"x":79,"y":180},{"x":71,"y":192},{"x":85,"y":185}]'), espelho: false },
            { regiaoId: 96, desenhoId: 1, nomeSimplificado: null, nome: 'Ventre', polygono: JSON.parse('[{"x":135,"y":168},{"x":101,"y":181},{"x":100,"y":191},{"x":124,"y":196},{"x":143,"y":195},{"x":158,"y":197},{"x":185,"y":196},{"x":203,"y":193},{"x":205,"y":186},{"x":176,"y":185},{"x":164,"y":184},{"x":149,"y":179},{"x":143,"y":176}]'), espelho: false },
            { regiaoId: 97, desenhoId: 1, nomeSimplificado: null, nome: 'Vazio do Flanco', polygono: JSON.parse('[{"x":117,"y":130},{"x":109,"y":134},{"x":109,"y":142},{"x":114,"y":146},{"x":122,"y":145},{"x":127,"y":142},{"x":128,"y":133}]'), espelho: false },
            { regiaoId: 98, desenhoId: 1, nomeSimplificado: null, nome: 'Anca', polygono: JSON.parse('[{"x":104,"y":110},{"x":100,"y":113},{"x":100,"y":120},{"x":105,"y":123},{"x":111,"y":121},{"x":113,"y":116},{"x":109,"y":111}]'), espelho: false },
            { regiaoId: 99, desenhoId: 1, nomeSimplificado: null, nome: 'Flanco', polygono: JSON.parse('[{"x":114,"y":117},{"x":110,"y":123},{"x":104,"y":124},{"x":102,"y":148},{"x":95,"y":171},{"x":99,"y":179},{"x":134,"y":167},{"x":130,"y":165},{"x":127,"y":153},{"x":127,"y":143},{"x":115,"y":147},{"x":109,"y":144},{"x":113,"y":130},{"x":122,"y":131},{"x":128,"y":131},{"x":131,"y":120}]'), espelho: false },
            { regiaoId: 100, desenhoId: 1, nomeSimplificado: null, nome: 'Lombo', polygono: JSON.parse('[{"x":103,"y":98},{"x":105,"y":108},{"x":111,"y":113},{"x":114,"y":116},{"x":133,"y":118},{"x":140,"y":103}]'), espelho: false },
            { regiaoId: 101, desenhoId: 1, nomeSimplificado: null, nome: 'Garupa', polygono: JSON.parse('[{"x":103,"y":100},{"x":69,"y":99},{"x":60,"y":100},{"x":47,"y":106},{"x":30,"y":114},{"x":29,"y":125},{"x":46,"y":125},{"x":68,"y":121},{"x":89,"y":116},{"x":99,"y":114},{"x":100,"y":111},{"x":104,"y":108}]'), espelho: false },
            { regiaoId: 102, desenhoId: 1, nomeSimplificado: null, nome: 'Nádega', polygono: JSON.parse('[{"x":29,"y":124},{"x":23,"y":143},{"x":27,"y":165},{"x":33,"y":179},{"x":34,"y":197},{"x":44,"y":198},{"x":40,"y":178},{"x":33,"y":161},{"x":31,"y":145},{"x":35,"y":125}]'), espelho: false },
            { regiaoId: 103, desenhoId: 1, nomeSimplificado: null, nome: 'Coxa', polygono: JSON.parse('[{"x":36,"y":125},{"x":32,"y":148},{"x":38,"y":168},{"x":43,"y":185},{"x":46,"y":200},{"x":70,"y":193},{"x":81,"y":174},{"x":95,"y":168},{"x":98,"y":156},{"x":102,"y":142},{"x":103,"y":128},{"x":103,"y":123},{"x":99,"y":119},{"x":98,"y":115},{"x":71,"y":121}]'), espelho: false },
            { regiaoId: 104, desenhoId: 1, nomeSimplificado: null, nome: 'Curvilhão', polygono: JSON.parse('[{"x":20,"y":228},{"x":17,"y":238},{"x":19,"y":245},{"x":26,"y":232}]'), espelho: false },
            { regiaoId: 105, desenhoId: 1, calcamentoId: 2, calcamentoIncompletoId: 49, zebruraId: 48, nomeSimplificado: 'Membro Posterior', nome: 'Perna', polygono: JSON.parse('[{"x":70,"y":195},{"x":49,"y":198},{"x":35,"y":199},{"x":34,"y":210},{"x":22,"y":228},{"x":41,"y":246},{"x":56,"y":218},{"x":60,"y":206},{"x":68,"y":202}]'), espelho: false },
            { regiaoId: 106, desenhoId: 1, calcamentoId: 2, calcamentoIncompletoId: 49, zebruraId: 48, nomeSimplificado: 'Membro Posterior', nome: 'Jarrete', polygono: JSON.parse('[{"x":27,"y":233},{"x":20,"y":245},{"x":20,"y":254},{"x":21,"y":266},{"x":33,"y":269},{"x":34,"y":264},{"x":40,"y":247}]'), espelho: false },
            { regiaoId: 107, desenhoId: 1, calcamentoId: 39, calcamentoIncompletoId: 51, zebruraId: 48, nomeSimplificado: 'Membro Posterior', nome: 'Canela Traseira', polygono: JSON.parse('[{"x":20,"y":265},{"x":18,"y":288},{"x":32,"y":292},{"x":33,"y":271}]'), espelho: false },
            { regiaoId: 108, desenhoId: 1, calcamentoId: 39, calcamentoIncompletoId: 51, zebruraId: 48, nomeSimplificado: 'Membro Posterior', nome: 'Boleto Traseiro', polygono: JSON.parse('[{"x":17,"y":289},{"x":16,"y":299},{"x":14,"y":305},{"x":22,"y":310},{"x":33,"y":306},{"x":33,"y":292}]'), espelho: false },
            { regiaoId: 109, desenhoId: 1, calcamentoId: 3, calcamentoIncompletoId: 50, zebruraId: 48, nomeSimplificado: 'Membro Posterior', nome: 'Quartela Traseira', polygono: JSON.parse('[{"x":33,"y":308},{"x":23,"y":311},{"x":24,"y":324},{"x":37,"y":318}]'), espelho: false },
            { regiaoId: 110, desenhoId: 1, nomeSimplificado: 'Membro Posterior', nome: 'Casco Traseiro', polygono: JSON.parse('[{"x":38,"y":319},{"x":24,"y":325},{"x":21,"y":333},{"x":44,"y":332},{"x":42,"y":322}]'), espelho: false },
            { regiaoId: 124, desenhoId: 1, nomeSimplificado: null, nome: 'Tronco', polygono: JSON.parse('[]'), espelho: false },
            { regiaoId: 128, desenhoId: 1, nomeSimplificado: null, nome: 'Costas', polygono: JSON.parse('[]'), espelho: false },

            { regiaoId: 202, desenhoId: 1, nomeSimplificado: null, nome: 'Espelho Membro Anterior Esquerda', polygono: JSON.parse('[{"x":253,"y":241},{"x":260,"y":267},{"x":260,"y":284},{"x":259,"y":302},{"x":268,"y":307},{"x":271,"y":319},{"x":287,"y":312},{"x":280,"y":306},{"x":275,"y":295},{"x":275,"y":277},{"x":278,"y":259},{"x":276,"y":245},{"x":274,"y":240},{"x":275,"y":232},{"x":276,"y":225},{"x":275,"y":208},{"x":274,"y":199},{"x":267,"y":210}]'), espelho: true },
            { regiaoId: 203, desenhoId: 1, nomeSimplificado: null, nome: 'Espelho Membro Posterior Esquerda', polygono: JSON.parse('[{"x":49,"y":235},{"x":61,"y":266},{"x":67,"y":289},{"x":68,"y":300},{"x":69,"y":314},{"x":75,"y":326},{"x":89,"y":320},{"x":79,"y":301},{"x":76,"y":283},{"x":75,"y":261},{"x":77,"y":245},{"x":79,"y":233},{"x":82,"y":218},{"x":85,"y":201},{"x":86,"y":197},{"x":70,"y":198},{"x":68,"y":202},{"x":62,"y":205},{"x":57,"y":213}]'), espelho: true },
            { regiaoId: 207, desenhoId: 1, nomeSimplificado: null, nome: 'Espelho Casco Anterior', polygono: JSON.parse('[]'), espelho: true },
            { regiaoId: 208, desenhoId: 1, nomeSimplificado: null, nome: 'Espelho Casco Posterior', polygono: JSON.parse('[]'), espelho: true },

            // pescoço
            { regiaoId: 111, desenhoId: 4, nomeSimplificado: null, nome: 'Cabeça', polygono: JSON.parse('[{"x":95,"y":15},{"x":85,"y":22},{"x":85,"y":33},{"x":73,"y":61},{"x":67,"y":68},{"x":65,"y":92},{"x":140,"y":93},{"x":137,"y":78},{"x":131,"y":70},{"x":117,"y":20},{"x":114,"y":14}]'), espelho: false },
            { regiaoId: 112, desenhoId: 4, nomeSimplificado: null, nome: 'Terço Cranial - Bordo Inferior', polygono: JSON.parse('[{"x":67,"y":94},{"x":71,"y":104},{"x":70,"y":139},{"x":134,"y":138},{"x":131,"y":119},{"x":134,"y":108},{"x":140,"y":95}]'), espelho: false },
            { regiaoId: 113, desenhoId: 4, nomeSimplificado: null, nome: 'Terço Médio - Bordo Inferior', polygono: JSON.parse('[{"x":69,"y":141},{"x":62,"y":176},{"x":139,"y":174},{"x":135,"y":139}]'), espelho: false },
            { regiaoId: 114, desenhoId: 4, nomeSimplificado: null, nome: 'Terço Caudal - Bordo Inferior', polygono: JSON.parse('[{"x":61,"y":177},{"x":35,"y":204},{"x":30,"y":218},{"x":165,"y":218},{"x":154,"y":202},{"x":143,"y":192},{"x":139,"y":176}]'), espelho: false },
            { regiaoId: 115, desenhoId: 4, nomeSimplificado: null, nome: 'Peito', polygono: JSON.parse('[{"x":29,"y":219},{"x":24,"y":239},{"x":17,"y":277},{"x":15,"y":307},{"x":175,"y":306},{"x":176,"y":296},{"x":175,"y":266},{"x":175,"y":239},{"x":170,"y":229},{"x":165,"y":219}]'), espelho: false },

            // focinho
            { regiaoId: 116, desenhoId: 5, nomeSimplificado: null, nome: 'Parte Superior', polygono: JSON.parse('[{"x":73,"y":17},{"x":33,"y":27},{"x":9,"y":54},{"x":12,"y":71},{"x":20,"y":83},{"x":31,"y":123},{"x":71,"y":134},{"x":94,"y":130},{"x":120,"y":116},{"x":124,"y":95},{"x":139,"y":67},{"x":140,"y":50},{"x":130,"y":36},{"x":111,"y":26}]'), espelho: false },
            { regiaoId: 117, desenhoId: 5, nomeSimplificado: null, nome: 'Parte Inferior', polygono: JSON.parse('[{"x":29,"y":124},{"x":32,"y":134},{"x":42,"y":147},{"x":55,"y":158},{"x":68,"y":163},{"x":85,"y":161},{"x":97,"y":153},{"x":107,"y":142},{"x":116,"y":130},{"x":119,"y":118},{"x":103,"y":129},{"x":83,"y":133},{"x":60,"y":133},{"x":54,"y":132}]'), espelho: false },
            { regiaoId: 126, desenhoId: 5, nomeSimplificado: null, nome: 'Focinho', polygono: JSON.parse('[]'), espelho: false },

            // Membros Anteriores
            { regiaoId: 118, desenhoId: 6, nomeSimplificado: null, nome: 'Lado Esquerdo', polygono: JSON.parse('[{"x":26,"y":26},{"x":38,"y":53},{"x":37,"y":68},{"x":30,"y":86},{"x":34,"y":109},{"x":42,"y":133},{"x":42,"y":150},{"x":38,"y":170},{"x":36,"y":187},{"x":41,"y":204},{"x":38,"y":219},{"x":30,"y":231},{"x":25,"y":242},{"x":26,"y":249},{"x":26,"y":258},{"x":33,"y":262},{"x":41,"y":257},{"x":55,"y":259},{"x":67,"y":262},{"x":74,"y":259},{"x":70,"y":242},{"x":71,"y":234},{"x":62,"y":213},{"x":63,"y":197},{"x":66,"y":183},{"x":67,"y":169},{"x":64,"y":158},{"x":65,"y":117},{"x":75,"y":99},{"x":77,"y":83},{"x":72,"y":69},{"x":82,"y":27},{"x":90,"y":27}]'), espelho: false },
            { regiaoId: 119, desenhoId: 6, nomeSimplificado: null, nome: 'Lado Direito', polygono: JSON.parse('[{"x":104,"y":25},{"x":110,"y":30},{"x":122,"y":71},{"x":119,"y":88},{"x":117,"y":102},{"x":128,"y":120},{"x":130,"y":161},{"x":124,"y":194},{"x":131,"y":209},{"x":130,"y":218},{"x":123,"y":231},{"x":122,"y":243},{"x":117,"y":257},{"x":118,"y":261},{"x":124,"y":262},{"x":138,"y":260},{"x":156,"y":261},{"x":166,"y":260},{"x":170,"y":253},{"x":167,"y":245},{"x":164,"y":232},{"x":157,"y":220},{"x":152,"y":202},{"x":159,"y":190},{"x":159,"y":169},{"x":152,"y":158},{"x":155,"y":119},{"x":164,"y":105},{"x":163,"y":78},{"x":158,"y":64},{"x":165,"y":42},{"x":168,"y":24}]'), espelho: false },

            // Membros Posteriores
            { regiaoId: 120, desenhoId: 7, nomeSimplificado: null, nome: 'Lado Esquerdo', polygono: JSON.parse('[{"x":21,"y":16},{"x":28,"y":37},{"x":29,"y":56},{"x":22,"y":80},{"x":28,"y":102},{"x":35,"y":122},{"x":36,"y":141},{"x":28,"y":182},{"x":34,"y":205},{"x":33,"y":218},{"x":22,"y":234},{"x":20,"y":243},{"x":16,"y":254},{"x":20,"y":260},{"x":33,"y":259},{"x":50,"y":259},{"x":67,"y":261},{"x":69,"y":254},{"x":65,"y":243},{"x":65,"y":231},{"x":55,"y":208},{"x":61,"y":183},{"x":63,"y":159},{"x":58,"y":153},{"x":60,"y":110},{"x":71,"y":88},{"x":70,"y":71},{"x":67,"y":59},{"x":78,"y":17}]'), espelho: false },
            { regiaoId: 121, desenhoId: 7, nomeSimplificado: null, nome: 'Lado Direito', polygono: JSON.parse('[{"x":106,"y":17},{"x":119,"y":62},{"x":114,"y":81},{"x":115,"y":95},{"x":125,"y":116},{"x":125,"y":158},{"x":121,"y":180},{"x":127,"y":203},{"x":126,"y":215},{"x":118,"y":233},{"x":118,"y":243},{"x":114,"y":255},{"x":113,"y":258},{"x":135,"y":261},{"x":155,"y":260},{"x":166,"y":258},{"x":169,"y":254},{"x":166,"y":245},{"x":162,"y":228},{"x":152,"y":212},{"x":154,"y":191},{"x":157,"y":167},{"x":152,"y":151},{"x":153,"y":109},{"x":160,"y":98},{"x":163,"y":74},{"x":160,"y":65},{"x":156,"y":50},{"x":164,"y":20}]'), espelho: false },
        ];

        _this.CaminhoImagemBase = function (img) {
            return _this.pastaImagem + _this.pastaBase + img + ".svg";
        };

        _this.CaminhoImagemParticularidade = function (img) {
            return _this.CaminhoImagem(_this.pastaParticularidades, img);
        };

        _this.CaminhoImagem = function (pasta, arquivo) {
            return _this.pastaImagem + pasta + arquivo + _this.formato;
        };

        _this.Callback = function () { }

        _this.EhNulo = function (x) {
            if ((x == 'undefined') || (x == null)) { return true; }
            else { return false; }
        };

        _this.EhArray = function (x) {
            if (!_this.EhNulo(x)) {
                if (x.constructor == Array) { return true; }
                else { return false; }
            }
            else { return false; }
        };

        _this.EhFuncao = function (x) {
            if (!_this.EhNulo(x)) {
                if (x instanceof Function) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        };

        _this.EhVazioOuNulo = function (x) {
            if ((x == 'undefined') || (x == null) || x == "") { return true; }
            else { return false; }
        };

        _this.ObterParticularidade = function (particularidadeId) {
            var obj = null;
            _this.tipoParticularidades.forEach(function (imgPart) {
                if (imgPart.particularidadeId === particularidadeId) {
                    obj = imgPart;
                    return;
                }
            });
            return obj;
        }

        _this.CarregarImagem = function (url) {
            return new Promise(function (solucao, rejeicao) {
                var img = new Image();
                img.onload = function () { solucao(img); };
                img.onerror = function () { rejeicao(new Error("carregamento: " + url + " falhou")); };
                img.src = url;
            });
        };

        _this.getXMLHttpRequest = function () {
            var activeXVersions = ["Msxml2.DOMDocument.6.0", "Msxml2.DOMDocument.3.0", "Msxml2.XMLHTTP", "Microsoft.XMLHTTP"];

            try {
                return new XMLHttpRequest;
            } catch (e) {
                for (var i = 0; i < activeXVersions.length; i++) {
                    try {
                        return new ActiveXObject(activeXVersions[i]);
                    } catch (e) { }
                }
            }
            return null;
        }

        _this.CarregarSVG = function (url) {
            return new Promise(function (solucao, rejeicao) {
                var xhr = _this.getXMLHttpRequest();
                xhr.open("GET", url, true);
                xhr.send();

                //var svg = SVG(_this.divBase);
                xhr.onreadystatechange = function () {
                    let imagem = xhr.responseXML;
                    if (imagem)
                        solucao(imagem.querySelector("svg"));
                };
            });
        };

        _this.getSVG = function (desenhoId) {
            let DOM = _this.divBase.querySelector("div#img_" + desenhoId);
            let svg = DOM.querySelector("svg");
            return svg;
        }

        _this.converterPosicaoPixelParaSVG = function (desenhoId, eixoX, eixoY) {
            var svg = _this.getSVG(desenhoId);
            var ponto = svg.createSVGPoint();
            ponto.x = eixoX;
            ponto.y = eixoY;
            return ponto.matrixTransform(svg.getScreenCTM().inverse());
        }

        _this.retornaDimensaoLaterais = function (nome) {
            switch (nome) {
                case "ESPIGA":
                    return { largura: 50, altura: 18 };
                case "CICATRIZ":
                case "GOLPE DE LANÇA":
                case "MARCA A FOGO":
                case "MARCA A FRIO":
                case "PÊLOS BRANCOS":
                case "RODOPIO":
                case "MANCHA":
                    return { largura: 15, altura: 15 };
                case "ARMINHADO":
                    return { largura: 12, altura: 12 };
                case "ZEBRURAS":
                    return { largura: 15, altura: 20 };
                case "BRAGADO":
                case "PISADURA":
                    return { largura: 8, altura: 8 };
            }

            return { largura: 20, altura: 20 };
        }

        _this.retornaDimensaoCabeca = function (nome) {
            switch (nome) {
                case "ESPIGA":
                    return { largura: 25, altura: 10 };
                case "CICATRIZ": 
                case "GOLPE DE LANÇA":
                case "MARCA A FOGO":
                case "MARCA A FRIO":
                case "PÊLOS BRANCOS":
                case "RODOPIO":
                case "MANCHA":
                case "ARMINHADO":
                case "ZEBRURAS":
                case "BRAGADO":
                case "PISADURA":
                    return { largura: 10, altura: 10 };
            }

            return { largura: 20, altura: 20 };
        }

        _this.retornaDimensaoPescoco = function (nome) {
            switch (nome) {
                case "ESPIGA":
                    return { largura: 20, altura: 6.7 };
                case "CICATRIZ":
                case "GOLPE DE LANÇA":
                case "MARCA A FOGO":
                case "MARCA A FRIO":
                case "PÊLOS BRANCOS":
                case "RODOPIO":
                case "MANCHA":
                case "ARMINHADO":
                case "ZEBRURAS":
                case "BRAGADO":
                case "PISADURA":
                    return { largura: 7, altura: 7 };
            }

            return { largura: 20, altura: 20 };
        }

        _this.retornaDimensaoFocinho = function (nome) {
            switch (nome) {
                case "ESPIGA":
                    return { largura: 20, altura: 6.7 };
                case "CICATRIZ":
                case "GOLPE DE LANÇA":
                case "MARCA A FOGO":
                case "MARCA A FRIO":
                case "PÊLOS BRANCOS":
                case "RODOPIO":
                case "MANCHA":
                case "ARMINHADO":
                case "ZEBRURAS":
                case "BRAGADO":
                case "PISADURA":
                    return { largura: 10, altura: 10 };
            }

            return { largura: 20, altura: 20 };
        }

        _this.retornaDimensaoMembrosInferiores = function (nome) {
            switch (nome) {
                case "ESPIGA":
                    return { largura: 20, altura: 6.7 };
                case "CICATRIZ":
                case "GOLPE DE LANÇA":
                case "MARCA A FOGO":
                case "MARCA A FRIO":
                case "PÊLOS BRANCOS":
                case "RODOPIO":
                case "MANCHA":
                case "ARMINHADO":
                case "ZEBRURAS":
                case "BRAGADO":
                case "PISADURA":
                    return { largura: 10, altura: 10 };
            }

            return { largura: 20, altura: 20 };
        }

        _this.retornarDimensao = function (particularidade) {
            let dimensao = particularidade.dimensao;
            if (dimensao == null) {
                if (particularidade.ehFixa)
                    switch (particularidade.categoriaId) {
                        case _this.ECategoria.CABECA: dimensao = { largura: 89, altura: 127 }; break; //CABEÇA
                        case _this.ECategoria.FOCINHO: dimensao = { largura: 47, altura: 57 }; break; //FOCINHO
                        case _this.ECategoria.MEMBRO: dimensao = { largura: 356, altura: 277 }; break; //MEMBRO
                        case _this.ECategoria.TRONCO: dimensao = { largura: 354, altura: 276 }; break; //TRONCO
                    }
                else {
                    if (particularidade.desenhoId == _this.EDesenho.LATERAL_DIREITA || particularidade.desenhoId == _this.EDesenho.LATERAL_ESQUERDA)
                        dimensao = _this.retornaDimensaoLaterais(particularidade.nome);
                    else if (particularidade.desenhoId == _this.EDesenho.CABECA)
                        dimensao = _this.retornaDimensaoCabeca(particularidade.nome);
                    else if (particularidade.desenhoId == _this.EDesenho.PESCOCO)
                        dimensao = _this.retornaDimensaoPescoco(particularidade.nome);
                    else if (particularidade.desenhoId == _this.EDesenho.FOCINHO)
                        dimensao = _this.retornaDimensaoFocinho(particularidade.nome);
                    else if (particularidade.desenhoId == _this.EDesenho.MEMBROS_ANTERIORES || particularidade.desenhoId == _this.EDesenho.MEMBROS_POSTERIORES)
                        dimensao = _this.retornaDimensaoMembrosInferiores(particularidade.nome);
                }
            }

            return dimensao;
        }
        
        _this.criarImagemSemMedidaParaOSVG = function (src, dimensao, eixoX, eixoY) {
            return new Promise(function (sucesso, falha) {
                var imagem = document.createElementNS("http://www.w3.org/2000/svg", "image");
                imagem.setAttributeNS(null, "x", Math.round(eixoX) + '');
                imagem.setAttributeNS(null, "y", Math.round(eixoY) + '');
                imagem.setAttributeNS(null, "preserveAspectRatio", 'none');
                if (dimensao != null) {
                    imagem.setAttributeNS(null, "width", dimensao.largura + '');
                    imagem.setAttributeNS(null, "height", dimensao.altura + '');
                }

                const toDataURL = function (url) {
                    return fetch(url)
                        .then(function (response) { return response.blob(); })
                        .then(function (blob) {
                            return new Promise((resolve, reject) => {
                                const leitor = new FileReader()
                                leitor.onloadend = function () { return resolve(leitor.result); }
                                leitor.onerror = reject
                                leitor.readAsDataURL(blob)
                            });
                        });
                }
                imagem.setAttributeNS(null, "style", 'pointer-events:none;');
                toDataURL(src).then(function (dataUrl) {
                    imagem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', dataUrl);
                    sucesso(imagem);
                });
            });
        }

        _this.inserirImagemNoSVG = function (imagem, desenhoId) {
            let svg = _this.getSVG(desenhoId);
            let g = svg.querySelector("g[id*=Particularidades]");
            let imagens = g.querySelectorAll("image");
            if (parseInt(imagem.getAttributeNS(null, "x")) > 1 &&
                parseInt(imagem.getAttributeNS(null, "y")) > 1)
                g.appendChild(imagem);
            else
                g.insertBefore(imagem, imagens[0]);
        }

        _this.calcularEixoRotacaoImagem = function (coordenadaSVG, larguraImagem, alturaImagem) {
            let centroX = (coordenadaSVG.x) + (larguraImagem * 0.2);
            let centroY = (coordenadaSVG.y) + (alturaImagem / 2);

            return { X: centroX, Y: centroY };
        }

        _this.rotacaoImagem = function (imagem, centro, grau) {
            imagem.setAttributeNS(null, "transform", `translate(${Math.round(centro.X)} ${Math.round(centro.Y)}) rotate(${Math.round(grau)}) translate(-${Math.round(centro.X)} -${Math.round(centro.Y)})`);
        }

        _this.desenharParticularidadeSvgImage = function (particularidade) {
            return new Promise(function (sucesso) {
                let dimensao = _this.retornarDimensao(particularidade);
                let coordenadaSVG = particularidade.coordenadaSVG;
                _this.criarImagemSemMedidaParaOSVG(particularidade.src, dimensao, coordenadaSVG.x, coordenadaSVG.y).then(function (imagem) {
                    if (particularidade.nome == _this.EParticularidade.ESPIGA) {
                        _this.inserirImagemNoSVG(imagem, particularidade.desenhoId);
                        let centro = _this.calcularEixoRotacaoImagem(coordenadaSVG, dimensao.largura, dimensao.altura);
                        _this.rotacaoImagem(imagem, centro, particularidade.grau);
                    } else
                        _this.inserirImagemNoSVG(imagem, particularidade.desenhoId);
                    sucesso();
                });
            });
        }

        _this.mapearParticularidade = function (particularidadeItem) {
            let particularidade = _this.ObterParticularidade(particularidadeItem.particularidadeId);
            
            if (particularidade.imagem.indexOf('{lado}') > -1) {
                let lado = particularidadeItem.desenhoId === _this.EDesenho.LATERAL_DIREITA ? "direito" : "esquerdo";
                particularidade.imagem = particularidade.imagem.replace('{lado}', lado);
            }

            let coordenadaSVG = { x: particularidadeItem.eixoX, y: particularidadeItem.eixoY };
            
            return {
                categoriaId: particularidade.categoriaId,
                particularidadeId: particularidadeItem.particularidadeId,
                nome: particularidade.nome,
                src: _this.CaminhoImagemParticularidade(particularidade.pasta + particularidade.imagem),
                coordenadaSVG: coordenadaSVG,
                dimensao: particularidade.dimensao,
                grau: particularidadeItem.radiante,
                regiaoId: particularidadeItem.desenhoAnimalRegiaoId,
                desenhoId: particularidadeItem.desenhoId,
                ehFixa: particularidade.ehFixa
            };
        }

        _this.novaParticularidadeInseridaEspelho = function(espelho){
            let coordenadaSVG = espelho.coordenadaSVG || { x: 0, y: 0 };
            
            return {
                categoriaId: espelho.categoriaId,
                particularidadeId: espelho.particularidadeId,
                nome: espelho.nome,
                src: _this.CaminhoImagemParticularidade(espelho.src),
                coordenadaSVG: coordenadaSVG,
                dimensao: espelho.dimensao,
                grau: 0,
                regiaoId: 0,
                desenhoId: espelho.desenhoId,
                ehFixa: true
            };
        }

        _this.tamanhoEPontoInicialDoCalcado = function(path, coordenadaSVG, dimensao){
            let tamanhoTotal = Math.round(path.getTotalLength());
            let pontosX = [];
            for (let i = 0; i <= tamanhoTotal; i++) {
                let posicao = path.getPointAtLength(i);
                if (Math.round(posicao.y) >= Math.round(coordenadaSVG.y - 1) &&
                    Math.round(posicao.y) <= Math.round(coordenadaSVG.y + 1))
                    pontosX.push(Math.round(posicao.x));
            }
            coordenadaSVG.x = pontosX.length == 0 ? 0 : Math.min.apply(null, pontosX);
            let pontoXOposto = pontosX.length == 0 ? 0 : Math.max.apply(null, pontosX);
            dimensao.largura = pontoXOposto - coordenadaSVG.x;
        }

        _this.buscarEspelhosFixos = function (particularidadeId, desenhoId, espelhamentos) {
            return new Promise(function (sucesso) {
                let espelhos = _this.espelhos.filter(function (espelho) { return espelho.particularidadeId == particularidadeId; });
                espelhos.forEach(function (e) {
                    let espelho = JSON.parse(JSON.stringify(e));
                    if (desenhoId === _this.EDesenho.LATERAL_DIREITA || desenhoId === _this.EDesenho.LATERAL_ESQUERDA) {
                        espelho.lado = desenhoId === _this.EDesenho.LATERAL_DIREITA ? "direito" : "esquerdo";
                        if (!espelho.src.contains(espelho.lado) && desenhoId == espelho.desenhoId)
                            return;
                    }
                    else
                        espelho.lado = undefined;

                    espelhamentos.push(espelho)
                });
                sucesso();
            });
        }
        
        _this.desenharEspelhamentoDosCalcadosNosMembrosAnterioresOuPosteriores = function (espelho, p) {
            let svg = _this.getSVG(espelho.desenhoId);
            let desenho = _this.desenhos.find(function (desenho) { return desenho.desenhoId == espelho.desenhoId; });
            // EDesenho.MEMBROS_ANTERIORES
            let lado = p.desenhoId === _this.EDesenho.LATERAL_ESQUERDA ? "Esquerdo" : "Direito";
            if (espelho.desenhoId == _this.EDesenho.MEMBROS_POSTERIORES)
                lado = p.desenhoId === _this.EDesenho.LATERAL_ESQUERDA ? "Direito" : "Esquerdo";

            let path = svg.querySelector(`g[id*=Pedacos] path[id*=${lado}]`);
            let correcaoProporcao = desenho.altura * (espelho.membro == _this.EMembro.POSTERIOR ? 0.35 : 0.45);
            espelho.coordenadaSVG.x = p.coordenadaSVG.x;
            espelho.coordenadaSVG.y = p.coordenadaSVG.y - correcaoProporcao;

            if (espelho.coordenadaSVG.y < 13)
                espelho.coordenadaSVG.y = 13;
            if (espelho.coordenadaSVG.y > 90)
                espelho.coordenadaSVG.y = 90;

            _this.tamanhoEPontoInicialDoCalcado(path, espelho.coordenadaSVG, espelho.dimensao);
        }

        _this.desenharEspelhamentoDosCalcadosNasLateraisDireitaEEsquerda = function (espelho, p) {
            let svg = _this.getSVG(espelho.desenhoId);

            let membro = espelho.membro == _this.EMembro.ANTERIOR ? "Anterior" : "Posterior";
            let lado = espelho.lado == "direito" ? "Direita" : "Esquerda";
            let path = svg.querySelector(`g[id*=Lateral] path[id*=${lado}${membro}]`);

            espelho.coordenadaSVG.x = p.coordenadaSVG.x;
            espelho.coordenadaSVG.y = p.coordenadaSVG.y;

            _this.tamanhoEPontoInicialDoCalcado(path, espelho.coordenadaSVG, espelho.dimensao);
        }

        _this.desenharEspelhamentoCalcados = function (calcados, desenhoId) {
            let particularidades = [];
            calcados.forEach(function (p) {
                if (p.desenhoId == desenhoId)
                    return;

                let regiao = _this.regioes.find(function (regiao) { return regiao.regiaoId === p.regiaoId; });
                let espelhos = _this.espelhos.filter(function (espelho) { return espelho.particularidadeId == p.particularidadeId && espelho.desenhoId == desenhoId; });

                espelhos.forEach(function (e) {
                    let espelho = JSON.parse(JSON.stringify(e));
                    if (!espelho.coordenadaSVG)
                        espelho.coordenadaSVG = { x: 0, y: 0 };
                    if (desenhoId === _this.EDesenho.LATERAL_DIREITA || desenhoId === _this.EDesenho.LATERAL_ESQUERDA) {
                        espelho.lado = desenhoId === _this.EDesenho.LATERAL_DIREITA ? "esquerdo" : "direito";
                        if (regiao.nomeSimplificado.contains("Membro"))
                            espelho.membro = regiao.nomeSimplificado == "Membro Posterior" ? _this.EMembro.POSTERIOR : _this.EMembro.ANTERIOR;
                    }

                    switch (espelho.desenhoId) {
                        case _this.EDesenho.MEMBROS_ANTERIORES:
                            if (regiao.nomeSimplificado != "Membro Anterior")
                                return;
                            _this.desenharEspelhamentoDosCalcadosNosMembrosAnterioresOuPosteriores(espelho, p);
                            break;
                        case _this.EDesenho.MEMBROS_POSTERIORES:
                            if (regiao.nomeSimplificado != "Membro Posterior")
                                return;
                            _this.desenharEspelhamentoDosCalcadosNosMembrosAnterioresOuPosteriores(espelho, p);
                            break;
                        case _this.EDesenho.LATERAL_DIREITA:
                            if (desenhoId !== _this.EDesenho.LATERAL_DIREITA)
                                return;
                            _this.desenharEspelhamentoDosCalcadosNasLateraisDireitaEEsquerda(espelho, p);
                            break;
                        case _this.EDesenho.LATERAL_ESQUERDA:
                            if (desenhoId !== _this.EDesenho.LATERAL_ESQUERDA)
                                return;
                            _this.desenharEspelhamentoDosCalcadosNasLateraisDireitaEEsquerda(espelho, p);
                            break;
                    }

                    let particularidade = _this.novaParticularidadeInseridaEspelho(espelho);
                    particularidades.push(particularidade);
                });
            });

            return particularidades;
        }

        this.Desenhar = function (particularidades, callback) {

            if (callback)
                _this.Callback = callback;

            let promessasDesenhos = [];
            let particularidadeASeremInseridas = [];

            _this.desenhos.forEach(function (desenho) {
                let url = _this.CaminhoImagemBase(desenho.imagem);

                promessasDesenhos.push(_this.CarregarSVG(url).then(function (svg) {
                    let largura = Math.ceil(desenho.largura * desenho.proporcao),
                        altura = Math.ceil(desenho.altura * desenho.proporcao);

                    svg.setAttributes({
                        id: "img_" + desenho.desenhoId,
                        width: largura,
                        height: altura,
                        class: desenho.classe,
                        style: {
                            border: 1
                        }
                    });

                    let div = document.createElement("div");
                    div.setAttributes({
                        id: "img_" + desenho.desenhoId,
                        desenhoId: desenho.desenhoId
                    });
                    div.appendChild(svg);

                    return div;
                }));
            });

            Promise.all(promessasDesenhos).then(function (involucros) {
                let promessas = [];
                involucros.forEach(function (involucro) {
                    const desenhoId = parseInt(involucro.getAttribute("desenhoId"));
                    _this.divBase.appendChild(involucro);

                    let particularidadesDesenho = particularidades.filter(function (particularidade) { return particularidade.desenhoId == desenhoId; });
                    particularidadesDesenho.forEach(function (particularidadeItem) {
                        let particularidade = _this.mapearParticularidade(particularidadeItem);

                        if (particularidade.nome.contains("CALÇADO") && !particularidade.nome.contains("COROA")) {
                            let regiao = _this.regioes.find(function (regiao) { return regiao.regiaoId === particularidadeItem.desenhoAnimalRegiaoId; });
                            let membro = regiao.nomeSimplificado == "Membro Anterior" ? "Anterior" : "Posterior";
                            let lado = desenhoId == _this.EDesenho.LATERAL_DIREITA ? "Direita" : "Esquerda";
                            let svg = involucro.querySelector("svg");
                            let pathDesenho = svg.querySelector(`g[id*=Lateral] path[id*=${lado}${membro}]`);

                            _this.tamanhoEPontoInicialDoCalcado(pathDesenho, particularidade.coordenadaSVG, particularidade.dimensao);
                        }

                        particularidadeASeremInseridas.push(particularidade);
                    });
                });

                involucros.forEach(function (involucro) {
                    const desenhoId = parseInt(involucro.getAttribute("desenhoId"));

                    let particularidadesId = particularidades.filter(function (particularidade) { return particularidade.desenhoId == desenhoId; })
                        .map(function (particularidade) { return particularidade.particularidadeId; });
                    let espelhos = _this.espelhos.filter(function (espelho) { return particularidadesId.contains(espelho.particularidadeId); });
                    espelhos.forEach(function (espelho) {
                        if (desenhoId === _this.EDesenho.LATERAL_DIREITA || desenhoId === _this.EDesenho.LATERAL_ESQUERDA) {
                            let lado = desenhoId === _this.EDesenho.LATERAL_DIREITA ? "direito" : "esquerdo";
                            if (espelho.desenhoId == _this.EDesenho.MEMBROS_ANTERIORES)
                                lado = lado == "direito" ? "direito" : "esquerdo";
                            espelho.src = espelho.src.replace("{lado}", lado);
                        }

                        let particularidade = _this.novaParticularidadeInseridaEspelho(espelho);
                        particularidadeASeremInseridas.push(particularidade);
                    });

                    let calcados = particularidades.map(function (p) { return _this.mapearParticularidade(p); })
                        .filter(function (p) { return p.nome.contains("CALÇADO") && !p.nome.contains("COROA"); });
                    if (calcados.length > 0)
                        particularidadeASeremInseridas = particularidadeASeremInseridas.concat(_this.desenharEspelhamentoCalcados(calcados, desenhoId));
                });

                promessas = particularidadeASeremInseridas.map(function (particularidade) {
                    return _this.desenharParticularidadeSvgImage(particularidade);
                });

                Promise.all(promessas).then(function () {
                    _this.Callback();
                }).catch(function (erro) {
                    console.log(erro);
                });
            });
        };
    };

    ParticularidadeSVG.fn = ParticularidadeSVG.prototype = {
        desenhar: function (arrParticularidades) {

            var _this = this;

            return new Promise(function (solucao, rejeicao) {
                _this.Desenhar(arrParticularidades, function () {
                    let SVGs = _this.divBase.querySelectorAll("svg");
                    for (var i = 0; i < SVGs.length; i++) {
                        document.getElementById(_this.localHtml).appendChild(SVGs[i]);
                    }
                    solucao();
                });
            });
        },
        obterBase64Desenho: function (arrParticularidades, config) {

            var _this = this;
            config = config || {};
            var padrao = { width: 1300, height: 500 };

            return new Promise(function (solucao) {
                _this.Desenhar(arrParticularidades, function () {
                    let SVGs = _this.divBase.querySelectorAll("svg");
                    var canvas = document.createElement("canvas");
                    canvas.width = config.width || padrao.width;
                    canvas.height = config.height || padrao.height;
                    var contexto = canvas.getContext("2d");
                    var posicao = {
                        "lado-direito-svg": { posX: 10, posY: 0 },
                        "lado-esquerdo-svg": { posX: 830, posY: 0 },
                        "cabeca-svg": { posX: 620, posY: 30 },
                        "pescoco-svg": { posX: 490, posY: 20 },
                        "focinho-svg": { posX: 600, posY: 360 },
                        "anteriores-svg": { posX: 380, posY: 260 },
                        "posteriores-svg": { posX: 800, posY: 240 }
                    };
                    var titulo = {
                        "lado-direito-svg": { texto: "Lado Direito", posX: 190, posY: 290 },
                        "lado-esquerdo-svg": { texto: "Lado Esquerdo", posX: 1110, posY: 290 },
                        "cabeca-svg": { texto: "Linha Superior", posX: 870, posY: 160, duaslinhas: true },
                        "cabeca-svg-line": { texto: "dos Olhos", posX: 870, posY: 180 },
                        "pescoco-svg": { texto: "Pescoço", posX: 500, posY: 160, duaslinhas: true },
                        "pescoco-svg-line": { texto: "vista inferior", posX: 500, posY: 180 },
                        "focinho-svg": { texto: "Focinho", posX: 645, posY: 490 },
                        "anteriores-svg": { texto: "Membros anteriores", posX: 440, posY: 470, duaslinhas: true, nomelados: true },
                        "anteriores-svg-line": { texto: "vista posterior", posX: 440, posY: 490 },
                        "anteriores-svg-esquerda": { texto: "Esquerdo", posX: 340, posY: 400 },
                        "anteriores-svg-direita": { texto: "Direito", posX: 520, posY: 400 },
                        "posteriores-svg": { texto: "Membros posteriores", posX: 870, posY: 470, duaslinhas: true, nomelados: true },
                        "posteriores-svg-line": { texto: "vista posterior", posX: 870, posY: 490 },
                        "posteriores-svg-esquerda": { texto: "Esquerdo", posX: 770, posY: 400 },
                        "posteriores-svg-direita": { texto: "Direito", posX: 950, posY: 400 }
                    };
                    var promessas = Array.from(SVGs).map(function (svg) {
                        return new Promise(function (solucao, rejeicao) {
                            let img = new Image();
                            let xml = new XMLSerializer().serializeToString(svg);
                            let svg64 = btoa(xml);
                            let b64Start = 'data:image/svg+xml;base64,';
                            let url = b64Start + svg64;
                            img.src = url;
                            img.onload = function () { solucao(img); }
                            img.onerror = function () { rejeicao(new Error("carregamento: " + classe + " falhou")); };
                        }).then(function (img) {
                            let classe = svg.getAttribute("class");
                            //IMAGENS
                            contexto.drawImage(img, posicao[classe].posX, posicao[classe].posY);

                            //TEXTOS DAS IMAGENS
                            contexto.font = "bold 18px Helvetica Neue, Helvetica, Arial, sans-serif";
                            contexto.fillStyle = "#333333";
                            contexto.textAlign = "center";
                            contexto.fillText(titulo[classe].texto, titulo[classe].posX, titulo[classe].posY);
                            if (titulo[classe].duaslinhas) {
                                let textocomplementar = titulo[classe + "-line"];
                                contexto.fillText(textocomplementar.texto, textocomplementar.posX, textocomplementar.posY);
                            }
                            if (titulo[classe].nomelados) {
                                let textoesquerdo = titulo[classe + "-esquerda"];
                                contexto.fillText(textoesquerdo.texto, textoesquerdo.posX, textoesquerdo.posY);
                                let textodireito = titulo[classe + "-direita"];
                                contexto.fillText(textodireito.texto, textodireito.posX, textodireito.posY);
                            }
                        });
                    });

                    Promise.all(promessas).then(function () {
                        solucao(canvas.toDataURL("image/png"));
                    });
                });
            });
        }
    }        

    if (!(Array.prototype.contains instanceof Function))
        Array.prototype.contains = function(obj) {
            var i = this.length;
            while (i--) {
                if (this[i] == obj) {
                    return true;
                }
            }
            return false;
        };
        
    if (!(String.prototype.contains instanceof Function))
        String.prototype.contains = function (it) {
            var valor = this.toString();
            return typeof valor === "string" ? valor.indexOf(it) != -1 : valor;
        };
    
    if (!Array.from) {
        Array.from = (function () {
            var toStr = Object.prototype.toString;
            var isCallable = function (fn) {
                return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
            };
            var toInteger = function (value) {
                var number = Number(value);
                if (isNaN(number)) { return 0; }
                if (number === 0 || !isFinite(number)) { return number; }
                return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
            };
            var maxSafeInteger = Math.pow(2, 53) - 1;
            var toLength = function (value) {
                var len = toInteger(value);
                return Math.min(Math.max(len, 0), maxSafeInteger);
            };
    
            // The length property of the from method is 1.
            return function from(arrayLike/*, mapFn, thisArg */) {
                // 1. Let C be the this value.
                var C = this;
    
                // 2. Let items be ToObject(arrayLike).
                var items = Object(arrayLike);
    
                // 3. ReturnIfAbrupt(items).
                if (arrayLike == null) {
                    throw new TypeError("Array.from requires an array-like object - not null or undefined");
                }
    
                // 4. If mapfn is undefined, then let mapping be false.
                var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
                var T;
                if (typeof mapFn !== 'undefined') {
                    // 5. else
                    // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                    if (!isCallable(mapFn)) {
                        throw new TypeError('Array.from: when provided, the second argument must be a function');
                    }
    
                    // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    if (arguments.length > 2) {
                        T = arguments[2];
                    }
                }
    
                // 10. Let lenValue be Get(items, "length").
                // 11. Let len be ToLength(lenValue).
                var len = toLength(items.length);
    
                // 13. If IsConstructor(C) is true, then
                // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
                // 14. a. Else, Let A be ArrayCreate(len).
                var A = isCallable(C) ? Object(new C(len)) : new Array(len);
    
                // 16. Let k be 0.
                var k = 0;
                // 17. Repeat, while k < len… (also steps a - h)
                var kValue;
                while (k < len) {
                    kValue = items[k];
                    if (mapFn) {
                        A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                    } else {
                        A[k] = kValue;
                    }
                    k += 1;
                }
                // 18. Let putStatus be Put(A, "length", len, true).
                A.length = len;
                // 20. Return A.
                return A;
            };
        }());
    }

    window.ParticularidadeSVG = ParticularidadeSVG;
})();