﻿(function () {
  window.Laudo = {
      amostraData: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Data da Colheita: ", bold: true }, { text: dados.dataColeta }], lineHeight: 1.3 });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "Data da recepção no laboratório: ", bold: true }, { text: dados.dataRecepcao }], lineHeight: 1.3 });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: "*" },
                  { stack: segundaColuna, width: "*" }
              ]
          };
      },
      amostraDataInicial: function (dados) {
          var primeiraColuna = [];
          var segundaColuna = [];
          dados.listaRelatorioEnsaio.forEach(function (relatorioEnsaio) {
              var nomeRelatorioEnsaio = relatorioEnsaio.nomeMetodoEnsaio + " " + relatorioEnsaio.nomeTipoAtestado;

              if (!relatorioEnsaio.kitElisa) {
                  primeiraColuna.push({ text: [{ text: "Data Inicial do " + nomeRelatorioEnsaio + ": ", bold: true }, { text: relatorioEnsaio.dataInicial }], lineHeight: 1.3 });
                  segundaColuna.push({ text: [{ text: "Data Final do " + nomeRelatorioEnsaio + ": ", bold: true }, { text: relatorioEnsaio.dataFinal }], lineHeight: 1.3 });
              }

          });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: "*" },
                  { stack: segundaColuna, width: "*" }
              ]
          };
      },
      amostraMatriz: function (dados) {
          return { text: [{ text: "Matriz: ", bold: true }, dados.matriz], lineHeight: 1.3 };
      },
      amostraMetodo: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Método(s) de Ensaio Utilizado(s): ", bold: true }, { text: dados.legislacaoRelatorioEnsaio }], lineHeight: 1.3 });

          dados.listaRelatorioEnsaio.forEach(function (relatorioEnsaio) {
              var nomeRelatorioEnsaio = relatorioEnsaio.nomeMetodoEnsaio + " " + relatorioEnsaio.nomeTipoAtestado;

              if (relatorioEnsaio.kitElisa)
                  primeiraColuna.push({ text: [{ text: "Data do " + nomeRelatorioEnsaio + ": ", bold: true }, { text: relatorioEnsaio.dataInicial }], lineHeight: 1.3 });
          });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: "*" }
              ]
          };
      },
      assinatura: function (dados) {
          return {
              margin: [-11, 0, 10, 0],
              absolutePosition: {
                  x: 13,
                  y: 740
              },
              table: {
                  fontSize: 7,
                  headerRows: 0,
                  widths: ["25%", "73%"],
                  body: [
                      [{ text: "Assinatura do Responsável Técnico:", bold: true, lineHeight: 3, border: [true, true, false, true], margin: [0, 0, 0, 0] }, { text: " ", margin: [0, 0, 0, 0], border: [false, true, true, true], lineHeight: 3 }]
                  ]
              }
          };
      },
      dadosAdicionais: function (dados) {
          return {
              margin: [-11, 0, 10, 0],
              absolutePosition: {
                  x: 10,
                  y: 775
              },
              stack:
                  [
                      { text: dados.mensagemDeclaracaoLaudo, bold: true, lineHeight: 1.0, alignment: "left", fontSize: 7, margin: [5, 0, 0, 0] },
                      { text: dados.formPop !== null ? "FORM. POP - " + dados.formPop : "FORM. POP", bold: true, lineHeight: 1.1, alignment: "left", fontSize: 8, margin: [5, 8, 0, 0] }
                  ]
          };
      },
      dadosAnimal: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Nome: ", bold: true }, { text: dados.nomeAnimal }], lineHeight: 1.3 });
          primeiraColuna.push({ text: [{ text: "Espécie: ", bold: true }, { text: dados.especie }], lineHeight: 1.3 });
          primeiraColuna.push({ text: [{ text: "Sexo: ", bold: true }, { text: dados.sexo }], lineHeight: 1.3 });

          if (dados.numeroRequisicao != null)
              primeiraColuna.push({ text: [{ text: "Classificação: ", bold: true }, { text: dados.classificacaoRequisicaoNome }], lineHeight: 1.3 });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "Idade: ", bold: true }, { text: dados.idade }], lineHeight: 1.3 });
          segundaColuna.push({ text: [{ text: "Raça: ", bold: true }, { text: dados.raca }], lineHeight: 1.3 });

          if (dados.sexo === "FÊMEA")
              segundaColuna.push({ text: [{ text: "Estado gestacional: ", bold: true }, { text: dados.castradoPrenha }], lineHeight: 1.3 });
          else
              segundaColuna.push({ text: [{ text: " ", bold: true }, { text: " " }], lineHeight: 1.3 });

          if (dados.numeroRequisicao != null)
              segundaColuna.push({ text: [{ text: "Local onde se encontra: ", bold: true }, { text: dados.localAnimal }], lineHeight: 1.3 });

          var terceiraColuna = [];
          terceiraColuna.push({ text: [{ text: "Finalidade do Exame: ", bold: true }, { text: dados.finalidadeTransitoNome }], lineHeight: 1.3 });
          terceiraColuna.push({ text: [{ text: "Pelagem: ", bold: true }, { text: dados.pelagemAnimal != null ? dados.pelagemAnimal.pelagemNome : "" }], lineHeight: 1.3 });
          terceiraColuna.push({ text: [{ text: "Registro Nº/Marca: ", bold: true }, { text: dados.numeroAssociacaoRaca }], lineHeight: 1.3 });
          terceiraColuna.push({ text: [{ text: "Município: ", bold: true }, { text: dados.localAnimalMunicipio }], lineHeight: 1.3 });

          return {
              margin: [0, 0],
              stack: [{
                  columns: [
                      { stack: primeiraColuna, width: "*" },
                      { stack: segundaColuna, width: "*" },
                      { stack: terceiraColuna, width: "*" }
                  ]
              }]
          };
      },
      dadosAnimalNumero: function (dados) {
          return { text: [{ text: "Número: ", bold: true }, { text: dados.numeroBrinco }], lineHeight: 1.3 };
      },
      informacoesRequisicao: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Requisição serie(s) nº(s): ", bold: true }, dados.numeroRequisicaoLaudo], lineHeight: 1.3 });
          primeiraColuna.push({ text: [{ text: "Nº Registro da amostra: ", bold: true }, dados.numeroRegistroAmostra], lineHeight: 1.3 });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "Relatório de Ensaio Nº: ", bold: true }, dados.numeroRelatorioEnsaio], lineHeight: 1.3 });
          segundaColuna.push({ text: [{ text: "Nº Lacre: ", bold: true }, dados.numeroLacre], lineHeight: 1.3 });

          return {
              margin: [0, 0, 0, 0],
              stack: [{
                  columns: [
                      { stack: primeiraColuna, width: "*" },
                      { stack: segundaColuna, width: "150" }
                  ]
              }]
          };
      },
      kits: function (dados) {
          return {
              margin: [-5, 0, 10, 0],
              table: {
                  fontSize: 7,
                  headerRows: 0,
                  widths: ["15%", "*", "*", "*", "*", "*", "*", "*"],
                  body: [
                      [
                          { text: "Nome Comercial:", border: [false] },
                          { text: dados.nomeComercial, border: [false] },
                          { text: "Fabricante:", border: [false] },
                          { text: dados.fabricante, border: [false] },
                          { text: "Partida/lote:", border: [false] },
                          { text: dados.partidaLote, border: [false] },
                          { text: "Validade:", border: [false] },
                          { text: dados.validade, border: [false] }
                      ]
                  ]
              }
          };
      },
      local: function (dados) {
          return {
              margin: [-5, 0, 10, 0],
              table: {
                  fontSize: 7,
                  headerRows: 0,
                  widths: ["35%", "65%"],
                  body: [
                      [{ text: "Local e data de emissão do Relatório de Ensaio:", bold: true, border: [false] }, { text: dados.localEDataEmissao, alignment: 'left', border: [false] }]
                  ]
              }
          };
      },
      medicoVeterinarioEndereco: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Endereço: ", bold: true }, { text: dados.enderecoVeterinario }], lineHeight: 1.3 });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "CRMV nº/UF: ", bold: true }, { text: dados.crmvVeterniario }], lineHeight: 1.3 });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: "70%" },
                  { stack: segundaColuna, width: "30%" }
              ]
          };
      },
      medicoVeterinarioEmailTelefone: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "E-mail: ", bold: true }, { text: dados.emailVeterinario }], lineHeight: 1.3 });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "Telefone: ", bold: true }, { text: dados.telefoneVeterinario }], lineHeight: 1.3 });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: "70%" },
                  { stack: segundaColuna, width: "30%" }
              ]
          };
      },
      medicoVeterinarioHabilitacao: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Port. Habilitação IAGRO - PNSE: ", bold: true }, { text: dados.habilitacaoPNSEVeterinario }], lineHeight: 1.3 });

          var segundaColuna = [];

          if (dados.laudoExameMormo)
              segundaColuna.push({ text: [{ text: "Port. Habilitação MORMO: ", bold: true }, { text: dados.habilitacaoMormoVeterinario }], lineHeight: 1.3 });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: "*" },
                  { stack: segundaColuna, width: "*" }
              ]
          };
      },
      medicoVeterinarioPrincipal: function (dados) {
          var primeiraColuna = [];
          primeiraColuna.push({ text: [{ text: "Nome: ", bold: true }, { text: dados.nomeVeterniario }], lineHeight: 1.3 });

          var segundaColuna = [];
          segundaColuna.push({ text: [{ text: "CPF: ", bold: true }, { text: dados.cpfVeterniario }], lineHeight: 1.3 });

          return {
              margin: [0, 0, 10, 0],
              columns: [
                  { stack: primeiraColuna, width: "70%" },
                  { stack: segundaColuna, width: "30%" }
              ]
          };
      },
      resultado: function (dados) {
          var corpo = [];

          for (i = 0; i < dados.length; i++) {
              if (dados[i].nomeTipoAtestado) {
                  corpo.push([{ text: dados[i].nomeTipoAtestado + "/" + dados[i].nomeMetodoEnsaio + ":", bold: true, border: [false] }, { text: dados[i].resultado, border: [false] }]);
              }
          }

          return {
              margin: [-5, 0, 10, 0],
              table: {
                  fontSize: 7,
                  headerRows: 0,
                  widths: ["*", "*", "*", "*", "*", "*", "*", "*"],
                  body: corpo
              }
          };
      },
      validadeInformacoesAdicionais: function (dados) {
          var dadosAdicionais = [];

          if (dados.laudoExameAIE)
              dadosAdicionais.push(
                  [{ text: "DATA DE VALIDADE AIE:", bold: true, border: [false] }, { text: dados.dataValidadeAIE, bold: true, colSpan: 3, border: [false, false, false, false] }, {}, {}]
              );

          if (dados.laudoExameMormo)
              dadosAdicionais.push(
                  [{ text: "DATA DE VALIDADE MORMO:", bold: true, border: [false] }, { text: dados.dataValidadeMormo, bold: true, colSpan: 3, border: [false, false, false, false] }, {}, {}]
              );

          if (dados.informacoesAdicionais != null)
              dadosAdicionais.push(
                  [{ text: "Informações Adicionais:", bold: true, border: [false] }, { text: dados.informacoesAdicionais, bold: false, colSpan: 3, border: [false, false, false, false] }, {}, {}]
              );

          return {
              margin: [-5, 0, 10, 0],
              table: {
                  fontSize: 7,
                  lineHeight: 1.1,
                  headerRows: 0,
                  widths: ["*", "*", "*", "*"],
                  body: dadosAdicionais
              }
          };

      }
  };
})();
