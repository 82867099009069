
const toCamel = function(s)  {
  return key = s.charAt(0).toUpperCase() + s.slice(1);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const isArray = function (a) {
  return Array.isArray(a);
};

function convertKeysToPascalCase(o) {
  
    if (isObject(o)) {
      const n = {};
  
      Object.keys(o).forEach(function(k) {
          n[toCamel(k)] = convertKeysToPascalCase(o[k]);
       });
  
      return n;
    } else if (isArray(o)) {
      return o.map(function(i) {
        return convertKeysToPascalCase(i);
      });
    }
    //console.log(o);
    return o;
  };

